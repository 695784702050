const listContents = (searchQuery) => {
    return `
        query{ listContents(searchQuery: "${searchQuery}"){user, content, title, summary, 
            subject {
                subject
                title
        }, resources,grade, s3URL, parent, subtree,ideaThematic}}
    `
}

export default listContents;