import React, { Component } from "react";
import { ResponsiveBar } from '@nivo/bar'
import update from 'immutability-helper';


var data = [
    {
        "country": "TLA",
        "produci": 0,
        "pratica": 0,
        "investiga": 0,
        "collabora": 0,
        "discuti": 0,
        "leggi_guarda_ascolta": 0
    },
]
const logdown = require('logdown')
const console = logdown('idea-producer-ld-public-bar-chart-component', { prefixColor: '#9c27b0' })


class BarChartComponent extends Component {

    constructor(props) {

        super(props);

        console.log(`in BarChartComponent constructor`, this.props.data);

        this.state = {
            data: this.props.data ? this.props.data : {},
            bar: data
        }
    }

    componentDidMount = () => {

        if (this.state.data.tlas) {
            this.calculateBar()
        }

    }

    componentDidUpdate = (prevProps) => {

        console.log(`in componentDidUpdate with `, this.props.data, ` and `, prevProps.data);

        if (this.props.data !== prevProps.data) {
            this.setState({ data: this.props.data }, () => this.calculateBar());
        }

    }

    calculateBar = () => {

        // console.log(`in calculate bar`);
        var investiga = 0;
        var pratica = 0;
        var produci = 0;
        var collabora = 0;
        var discuti = 0;
        var leggi_guarda_ascolta = 0;

        for (const tla of this.state.data.tlas) {
            for (const action of tla.actions) {
                switch (action.actionType) {
                    case "inv":
                        console.log(`in inv with `, action.minutes, ' and bar ', investiga);
                        investiga += action.minutes;
                        break;
                    case "pra":
                        console.log(`in pra with `, action.minutes, ' and bar ', pratica);
                        pratica += action.minutes;
                        break;
                    case "pro":
                        console.log(`in pro with `, action.minutes, ' and bar ', produci);
                        produci += action.minutes;
                        break;
                    case "col":
                        console.log(`in col with `, action.minutes, ' and bar ', collabora);
                        collabora += action.minutes;
                        break;
                    case "dis":
                        console.log(`in dis with `, action.minutes, ' and dic ', discuti);
                        discuti += action.minutes;
                        break;
                    case "rwl":
                        console.log(`in dis with `, action.minutes, ' and dic ', leggi_guarda_ascolta);
                        leggi_guarda_ascolta += action.minutes;
                        break;
                }
            }
        }
        this.setState(prevState => ({
            bar: update(prevState.bar, {
                [0]: {
                    investiga: { $set: investiga },
                    pratica: { $set: pratica },
                    produci: { $set: produci },
                    collabora: { $set: collabora },
                    discuti: { $set: discuti },
                    leggi_guarda_ascolta: { $set: leggi_guarda_ascolta }

                }
            })
        }), () => console.log(`statebar: `, this.state.bar))
    }

    render() {
        console.log('bar component render')
        return (
                <ResponsiveBar
                    data={this.state.bar}
                    keys={[
                        "produci",
                        "pratica",
                        "investiga",
                        "collabora",
                        "discuti",
                        "leggi_guarda_ascolta"
                    ]}
                    indexBy="country"
                    margin={{
                        "top": 50,
                        "right": 0,
                        "bottom": 50,
                        "left": 0
                    }}
                    padding={0.3}
                    layout="horizontal"
                    colors="nivo"
                    colorBy={d => {
                        console.log(`colors: `, d);
                        return ('red')
                    }
                    }
                    defs={[
                        {
                            "id": "produci",
                            "type": "patternDots",
                            "background": "#BDEA75",
                            "color": "#BDEA75",
                            "size": 4,
                            "padding": 1,
                            "stagger": true
                        },
                        {
                            "id": "pratica",
                            "type": "patternDots",
                            "background": "#BB98DC",
                            "color": "#BB98DC",
                            "size": 4,
                            "padding": 1,
                            "stagger": true
                        },
                        {
                            "id": "investiga",
                            "type": "patternDots",
                            "background": "#F8807F",
                            "color": "#F8807F",
                            "size": 4,
                            "padding": 1,
                            "stagger": true
                        },
                        {
                            "id": "collabora",
                            "type": "patternLines",
                            "background": "#FFCD00",
                            "color": "#FFCD00",
                            "rotation": -45,
                            "lineWidth": 6,
                            "spacing": 10
                        },
                        {
                            "id": "discuti",
                            "type": "patternLines",
                            "background": "#7AAEEA",
                            "color": "#7AAEEA",
                            "rotation": -45,
                            "lineWidth": 6,
                            "spacing": 10
                        },
                        {
                            "id": "leggi_guarda_ascolta",
                            "type": "patternLines",
                            "background": "#67DBF2",
                            "color": "#67DBF2",
                            "rotation": -45,
                            "lineWidth": 6,
                            "spacing": 10
                        }
                    ]}
                    fill={[
                        {
                            "match": {
                                "id": "produci"
                            },
                            "id": "produci"
                        },
                        {
                            "match": {
                                "id": "pratica"
                            },
                            "id": "pratica"
                        },
                        {
                            "match": {
                                "id": "investiga"
                            },
                            "id": "investiga"
                        },
                        {
                            "match": {
                                "id": "collabora"
                            },
                            "id": "collabora"
                        },
                        {
                            "match": {
                                "id": "discuti"
                            },
                            "id": "discuti"
                        },
                        {
                            "match": {
                                "id": "leggi_guarda_ascolta"
                            },
                            "id": "leggi_guarda_ascolta"
                        }
                    ]}
                    borderColor={{
                        "from": "color",
                        "modifiers": [
                            [
                                "darker",
                                1.6
                            ]
                        ]
                    }}
                    axisTop={null}
                    axisRight={null}
                    axisBottom={null}
                    axisLeft={null}
                    enableGridY={false}
                    labelSkipWidth={12}
                    labelSkipHeight={12}
                    labelTextColor={{
                        "from": "color",
                        "modifiers": [
                            [
                                "darker",
                                1.6
                            ]
                        ]
                    }}
                    legends={[]}
                    animate={true}
                    motionStiffness={90}
                    motionDamping={15}
                />
        )

    }
}

export default BarChartComponent;