import * as IdeaTypes from "../constants/auth";
import update from 'immutability-helper';

const authState = {
  userinfo: {},
  authenticated: false,
  cookie: "",
  pathname: ""
};

export const authReducer = (state = authState, action: IdeaTypes.IdeaAuthTypes) => {
  switch (action.type) {
    case IdeaTypes.SET_AUTHENTICATED:
        return update(state, { authenticated: { $set: false } });
    case IdeaTypes.LOG_USER:
      return update(state, { authenticated: { $set: true } });
    case IdeaTypes.LOGOUT:
      return update(state, { cookie: { $set: "" }, authenticated: { $set: false } });
    case IdeaTypes.DID_SET_COOKIE:
      return update(state, { cookie: { $set: action.cookie } });
    case IdeaTypes.DID_GET_USERINFO:
      return update(state, { userinfo: { $set: action.userinfo } });
    case IdeaTypes.SET_USERINFO:
      return { ...state, userinfo: action.userinfo };

    case IdeaTypes.SET_PATHNAME:
      return update(state, { pathname: { $set: action.pathname } });
    default:
      return state
  }
}

