let config:any = {}

if (process.env.REACT_APP_STAGE === 'PROD') {
    config = require('../config-prod.json');
} else {
    config = require('../config-dev.json');
}

const axios = require('axios');

export const doLoadLDJSONfromId = async (id: string) => {
    return await axios.get(`${config.learningDesignerConfiguration.jsonUrlPrefix}${id}`);
}
export const doLoadLDJSONfromURL = async (url: string) => {
    return await axios.get(`${url}`);
}

