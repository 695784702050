export const getClipboardData = (state) => state.content.appstate.clipboardData;
export const getAppstate = (state) => state.content.appstate;

export const getPreviousAppstate = (state) => state.content.appstate.prev;
export const getCurrentAppstate = (state) => state.content.appstate.current;



export const getApperror = (state) => state.content.apperror;

export const getGetcontent = (state) => state.content.getcontent;
export const getGetPubliccontent = (state) => state.content.getpubliccontent;
export const getCurrentData = (state) => state.content.getcontent.current;
export const getSearchResults = (state) => state.content.contents;

export const getUploadingState = (state) => state.content.uploading;
export const getUpdateProgress = (state) => state.content.update_progress;

export const getListContentQuery = (state)=> state.content.query;

export const getMutationNok = (state)=> state.content.mutation.nok;
export const getMutationOk = (state)=> state.content.mutation.ok;

export const getMutation = (state) => state.content.mutation.mutation;

export const getFormData = (state) => state.content.newcontent.formdata;
export const getFormErrors= (state) =>  state.content.formErrors;