export const LIST_CONTENTS = "LIST_CONTENTS";
export const SET_USERINFO = "SET_USERINFO";
export const FETCH_CONTENTS = "FETCH_CONTENTS";
export const GET_CONTENT = "GET_CONTENT";
export const GET_PUBLIC_CONTENT = "GET_PUBLIC_CONTENT";
export const SET_CONTENTS = "SET_CONTENTS";

export const SET_CURRENT_CONTENT = "SET_CURRENT_CONTENT";
export const SET_PUBLIC_CURRENT_CONTENT = "SET_PUBLIC_CURRENT_CONTENT";
export const ADD_CONTENT = "ADD_CONTENT";

export const SET_FORMDATA = "SET_FORMDATA";
export const UNSET_FORMDATA = "UNSET_FORMDATA";

export const ADD_CONTENT_OK = "ADD_CONTENT_OK";
export const ADD_CONTENT_NOTOK = "ADD_CONTENT_NOTOK";

export const UPLOADING = "UPLOADING";
export const ABORT_UPLOAD = "ABORT_UPLOAD";
export const START_ADDCONTENT = "START_ADDCONTENT";

export const UPLOAD_SUCCESS = "UPLOAD_SUCCESS";
export const UPLOAD_UPDATE_PROGRESS = "UPLOAD_UPDATE_PROGRESS";

export const SET_APP_STATE = "SET_APP_STATE";
export const SET_APP_STATE_CLIPBOARD = "SET_APP_STATE_CLIPBOARD";

export const IMPORT_LD_JSON = "IMPORT_LD_JSON";
export const SET_ERROR = "SET_ERROR";
export const SET_FORM_ERRORS = "SET_FORM_ERRORS";

export const FORM_SUBMIT_NEW_CONTENT = "FORM_SUBMIT_NEW_CONTENT";
export const FORM_SUBMIT_FORK_CONTENT = "FORM_SUBMIT_FORK_CONTENT";

export const SET_LDJSON_FROM_URL = "SET_LDJSON_FROM_URL";
export const DID_SET_LDJSON_FROM_URL = "DID_SET_LDJSON_FROM_URL";

export const SET_LDJSON_FROM_ID = "SET_LDJSON_FROM_ID";
export const DID_SET_LDJSON_FROM_ID = "DID_SET_LDJSON_FROM_ID";


export enum AppState {
    IDLE = 0,
    LIST_ITEMS, //1
    ADD_NEW_CONTENT, //2
    EDIT_CONTENT, //3
    IMPORT_CONTENT_BY_ID_START,
    IMPORT_CONTENT_BY_ID_END,
    IMPORT_CONTENT_BY_ID_PROCESSING,//6
    IMPORT_CONTENT_BY_ID_FAILED,

    IMPORT_CONTENT_BY_JSON_START,
    IMPORT_CONTENT_BY_JSON_END,
    IMPORT_CONTENT_BY_JSON_PROCESSING,
    IMPORT_CONTENT_BY_JSON_FAILED,
    LOADING, //12
    BLOCK_UI, //13
    SHOW_PROFILE, //14
    IMPORT_CONTENT //15
}

export enum AppError {
    NO_ERROR = 0,
    GENERIC_ERROR = 1,
    EMPTY_ID_ERROR,
    GET_CONTENT_QUERY_ERROR,
    EMPTY_FORM_FIELD,
    BAD_MUTATION_ERROR,
    UPLOAD_ERROR,
    FEDERATED_SIGNIN_ERROR,
    IMPORT_CONTENT_BY_JSON_FAILED,
    USER_CONTENTS_LOAD_FAILED
}

interface SetFormErrors {
    type: typeof SET_FORM_ERRORS,
    errors: any
}

interface SetErrorAction {
    type: typeof SET_ERROR,
    code: AppError,
    returnstate?: AppState,
    params?: any
}


interface ListContentsAction {
    type: typeof LIST_CONTENTS,
    data: any[]
}

interface SetFetchContentsActions {
    type: typeof FETCH_CONTENTS,
    query: string
}
interface GetContentAction {
    type: typeof GET_CONTENT,
    query: string,
    content: string
}
interface GetPublicContentAction {
    type: typeof GET_PUBLIC_CONTENT,
    query: string,
    content: string
}
interface SetAddContentAction {
    type: typeof ADD_CONTENT,
    mutationParams: string
}
interface SetFormdataAction {
    type: typeof SET_FORMDATA,
    params: string
}
interface UnsetFormdataAction {
    type: typeof UNSET_FORMDATA
}
interface SetAddContentOkAction {
    type: typeof ADD_CONTENT_OK,
    ok: boolean
}
interface SetAddContentNotOkAction {
    type: typeof ADD_CONTENT_NOTOK,
    notok: boolean
}

interface SetContentsAction {
    type: typeof SET_CONTENTS,
    contents: any[]
}
interface SetAppStateAction {
    type: typeof SET_APP_STATE,
    appstate: AppState,
    params?: any
}
interface SetAppStateClipboardAction {
    type: typeof SET_APP_STATE_CLIPBOARD,
    clipboardData: any
}
interface SetCurrentContentAction {
    type: typeof SET_CURRENT_CONTENT,
    current: any
}
interface SetPublicCurrentContentAction {
    type: typeof SET_PUBLIC_CURRENT_CONTENT,
    publiccurrent: any
}
interface UploadingAction {
    type: typeof UPLOADING,
    status: true,
    file: any
    path: string
}
interface StartAddContentAction {
    type: typeof START_ADDCONTENT,
    file: any,
    path: string
}
interface UploadAbortAction {
    type: typeof ABORT_UPLOAD
}
interface UploadSuccess {
    type: typeof UPLOAD_SUCCESS
}
interface ImportLDJSONAction {
    type: typeof IMPORT_LD_JSON,
    url: string,
    params?: any
}

interface UploadUpdateProgressAction {
    type: typeof UPLOAD_UPDATE_PROGRESS,
    value: any
}

interface formSubmitNewContentAction {
    type: typeof FORM_SUBMIT_NEW_CONTENT,
    id: string
    data: any
}
interface formSubmitForkContentAction {
    type: typeof FORM_SUBMIT_FORK_CONTENT,
    id: string,
    data: any
}


interface SetLDJSONfromIDAction {
    type: typeof SET_LDJSON_FROM_ID,
    id: string
}
interface DidSetLDJSONfromIDAction {
    type: typeof DID_SET_LDJSON_FROM_ID,
    id: string,
    json: any
}
interface SetLDJSONfromURLAction {
    type: typeof SET_LDJSON_FROM_URL,
    url: string
}
interface DidSetLDJSONfromURLAction {
    type: typeof DID_SET_LDJSON_FROM_URL,
    url: string,
    json: any
}

export type IdeaContentTypes =
    SetFormdataAction | ImportLDJSONAction | SetErrorAction | formSubmitForkContentAction | formSubmitNewContentAction | DidSetLDJSONfromURLAction |
    UploadSuccess | UnsetFormdataAction | SetCurrentContentAction | SetPublicCurrentContentAction | SetLDJSONfromURLAction | SetLDJSONfromIDAction |
    ListContentsAction | UploadingAction | UploadAbortAction | StartAddContentAction | SetAppStateClipboardAction | DidSetLDJSONfromIDAction|
    SetAddContentOkAction | SetAddContentNotOkAction | GetContentAction | GetPublicContentAction | SetAppStateAction |
    SetFetchContentsActions | SetContentsAction | SetAddContentAction | UploadUpdateProgressAction | SetFormErrors; 
