import * as IdeaSearchTypes from "../constants/search";
import update from 'immutability-helper';
import SearchResult from "../../components/SearchResult";

var _ = require('lodash');

const initialState = {
    searchResults: [],
    searchTerm: "",
    searchBool: false
}
// https://github.com/reduxjs/redux/issues/2709 
export const searchReducer = (state = initialState, action: IdeaSearchTypes.IdeaSearchTypes | any) => {
    switch (action.type) {
        case IdeaSearchTypes.WILL_SEARCH_TERM:
            return update(state, { searchTerm: { $set: action.searchTerm } });
        case IdeaSearchTypes.DID_SEARCH_TERM:
            let newestBySubtree = [];
            if(action.results.length==0 && action.searchBool==false){
                action.searchBool=true ;
            }else{
            const sorted : any = _.orderBy(action.results, "creationDate", "desc");
            console.log('SORTED in SEARCH', sorted)
            newestBySubtree = _.uniqBy(sorted, "subtree");
            }
            return update(state, { searchResults: { $set:newestBySubtree  }, searchBool: { $set: action.searchBool} });
        case IdeaSearchTypes.DID_SET_SEARCH_TERM:
            return update(state, { searchTerm: { $set: action.searchTerm } });
        default:
            return state
    }
}