import { put, call, delay, take, takeLatest } from 'redux-saga/effects';
import * as IdeaTypes from '../constants/auth';
import * as IdeaAuthActions from '../actions/auth';
import { getCookie } from '../selectors/auth';
import { getLocationPathname } from '../selectors/router';
import Cookies from 'js-cookie';
import { select } from 'redux-saga/effects';
import * as AuthApi from '../../api/auth'
import { push } from 'connected-react-router';

const pathname_schema = require('../../configs/pathname_schema.json');
const Ajv = require('ajv');
const ajv = new Ajv();
const validate = ajv.compile(pathname_schema);
let config: any = {}
if (process.env.REACT_APP_STAGE === 'PROD') {
    config = require('../../config-prod.json');
} else {
    config = require('../../config-dev.json');
}

const axios = require('axios');

export function* willLoadAuthSagas() {
    yield takeLatest(IdeaTypes.WILL_SET_COOKIE, willSetCookie);
    yield takeLatest(IdeaTypes.WILL_GET_USERINFO, willGetUserInfo);

    yield call(willCheckCookie);
    yield takeLatest(IdeaTypes.WILL_CHECK_COOKIE, willCheckCookie);
}
export function* willSetCookie(action: IdeaTypes.WillSetCookieAction) {
    console.log('Inside willSetCookie saga', action.cookie);

    const isValid = validate(action.cookie);
    if (!isValid) { // if pathname is missing or invalid, check if browser contain a valid cookie
        let isBrowserCoookieValid = false;
        if (undefined === Cookies.getJSON(config.authentication.cookie)) {
            console.log('Invalid Pathname', action.cookie);
            window.location.assign(config.authentication.login);
            return;
        }
        else {
            console.log('isBrowserCoookieValid', isValid, isBrowserCoookieValid, 'equality', Cookies.getJSON(config.authentication.cookie) === undefined, 'Cookie.getHson', Cookies.getJSON(config.authentication.cookie), 'Action.cookie', action.cookie)
            if (isBrowserCoookieValid) {
                yield put(IdeaAuthActions.didSetCookie(Cookies.get(config.authentication.cookie)));
                yield put(IdeaAuthActions.loginUser());
                return;
            }
            console.log('Invalid Pathname', action.cookie);
            return;
        }
    }
    console.log('isValid', isValid, 'Cookie.getHson', Cookies.getJSON(config.authentication.cookie), 'Action.cookie', action.cookie)

    Cookies.set(config.authentication.cookie, JSON.stringify(action.cookie), { expires: ((action.cookie.expires_in) / 3600) / 24, secure: true });
    if (typeof action.cookie === 'string') {
        yield put(IdeaAuthActions.didSetCookie(action.cookie));
    } else {
        yield put(IdeaAuthActions.didSetCookie(JSON.stringify(action.cookie)));
    }
    yield put(IdeaAuthActions.loginUser());


}

export function* willGetUserInfo() {
    const cookie = yield select(getCookie);
    const pathname = yield select(getLocationPathname);
    console.log('COOKIE USERINFO', Cookies.getJSON(config.authentication.cookie))
    try {
        let result = yield call(AuthApi.doLoadUserInfo, Cookies.getJSON(config.authentication.cookie).access_token);
        // alert(JSON.stringify(result))
        yield put(IdeaAuthActions.didGetUserInfo(result.data))
    } catch (e) {
        console.log('WillGetuSerInfo error', e)
        if (pathname.startsWith('/oauth')) {
            console.log('WillGetuSerInfo error oauth')

            yield put(IdeaAuthActions.logout());
            Cookies.remove(config.authentication.cookie)

            window.location.assign(config.authentication.login)
        }
        else {
            // public page, we don't care about logging in again, just does not try to show user name
            console.log('WillGetuSerInfo error search');
            Cookies.remove(config.authentication.cookie)

            window.location.assign(config.authentication.login)
            yield put(IdeaAuthActions.setAuthenticated(false));
        }
        // }
    }
}

export function* willCheckCookie() {
    const pathname = yield select(getLocationPathname);
    console.log('init auth sagas');
    yield delay(1000)
    if (Cookies.getJSON(config.authentication.cookie) === undefined) {
        console.log('no cookie found');
        // yield put(push('/'))
        // if (pathname.startsWith('/oauth')) {
        //alert('Autorizzazione scaduta, verrai reindirizzato alla pagina di login')
        window.location.assign(config.authentication.login)
        // }
    } else {
        console.log('cookie found');
        yield put(IdeaAuthActions.willSetCookie(Cookies.getJSON(config.authentication.cookie)))
    }
}
