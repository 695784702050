import { IdeaAuthTypes } from "../constants/auth";

import * as IdeaTypeEnums from "../constants/auth";
import { ActionCreator } from "redux";
export const loginUser: ActionCreator<IdeaTypeEnums.LogUserAction> = () => {
  return { type: IdeaTypeEnums.LOG_USER }
};

export const logout: ActionCreator<IdeaTypeEnums.LogoutUserAction> = () => {
  return { type: IdeaTypeEnums.LOGOUT }
}

// export const setCookie: ActionCreator<IdeaTypeEnums.WillSetCookieAction> = (cookie: any) => {
//   return { type: IdeaTypeEnums.WILL_SET_COOKIE, cookie: cookie }
// };


export const setPathname = (pathname: string): IdeaAuthTypes => {
  return { type: IdeaTypeEnums.SET_PATHNAME, pathname: pathname }
};

export const didSetCookie: ActionCreator<IdeaTypeEnums.DidSetCookieAction> = (cookie: any) => {
  return { type: IdeaTypeEnums.DID_SET_COOKIE, cookie: cookie }
}
export const willSetCookie: ActionCreator<IdeaTypeEnums.WillSetCookieAction> = (cookie: any) => {
  return { type: IdeaTypeEnums.WILL_SET_COOKIE, cookie: cookie }
}
export const willGetUserInfo: ActionCreator<IdeaTypeEnums.WillGetUserInfo> = () => {
  return { type: IdeaTypeEnums.WILL_GET_USERINFO }
}

export const didGetUserInfo: ActionCreator<IdeaTypeEnums.DidGetUserInfo> = (userinfo: any) => {
  return { type: IdeaTypeEnums.DID_GET_USERINFO, userinfo: userinfo }
}

export const willCheckCookie: ActionCreator<IdeaTypeEnums.WillCheckCookie> = () => {
  return { type: IdeaTypeEnums.WILL_CHECK_COOKIE }
} 

export const setAuthenticated: ActionCreator<IdeaTypeEnums.SetAuthenticated> = (auth : boolean) => {
  return { type: IdeaTypeEnums.SET_AUTHENTICATED, auth: auth }
}