let config:any = {}
if (process.env.REACT_APP_STAGE === 'PROD') {
    config = require('../config-prod.json');
} else {
    config = require('../config-dev.json');
}

const axios = require('axios');

export const doLoadUserInfo = async (token: string) => {
    return await axios.get(config.authentication.userInfoUrl, {
        headers: { 'Authorization': 'Bearer ' + token }
    })
}