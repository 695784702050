import * as IdeaTypes from "../constants/content";
import update from 'immutability-helper';
let config:any = {}
if (process.env.REACT_APP_STAGE === 'PROD') {
  config = require('../../config-prod.json');
} else {
  config = require('../../config-dev.json');
}


const initialState = {
  appstate: { current: IdeaTypes.AppState.IDLE, prev: IdeaTypes.AppState.IDLE, clipboardData: "", params: {} },
  apperror: { code: IdeaTypes.AppError.NO_ERROR, returnstate: IdeaTypes.AppState.IDLE, params: {} },

  userAuthenticated: false,
  searchResults: [],
  cookie: null,
  pathname: null,
  contents: [],
  mutation: {
    mutation: "",
    ok: false,
    nok: false
  },
  newcontent: {
    formdata: {}
  },
  query: "",
  uploading: false,
  update_progress: 0,
  getcontent: {
    query: "",
    content: "",
    current: {}
  },
  getpubliccontent: {
    publicquery: "",
    publiccontent: "",
    publiccurrent: {}
  },
  formErrors: {}
};

var _ = require('lodash');

export const contentReducer = (state = initialState, action: IdeaTypes.IdeaContentTypes) => {
  switch (action.type) {

    case IdeaTypes.LIST_CONTENTS:
      return { ...state, searchResults: action.data };


    // case IdeaTypes.SET_USERINFO:
    //   return { ...state, userinfo: action.userinfo };


    case IdeaTypes.FETCH_CONTENTS:
      return { ...state, query: action.query };

    case IdeaTypes.GET_CONTENT:
      const query: string = action.query;
      const content: string = action.content;
      return update(state, { getcontent: { query: { $set: query }, content: { $set: content } } });
    case IdeaTypes.GET_PUBLIC_CONTENT:
      const publicquery: string = action.query;
      const publiccontent: string = action.content;
      console.log("reducer:", publiccontent)
      return update(state, { getpubliccontent: { publicquery: { $set: publicquery }, publiccontent: { $set: publiccontent } } });
    case IdeaTypes.SET_CONTENTS:
      const con: any = action.contents
      const sorted: any = _.orderBy(con, "creationDate", "desc");
      console.log('SORTED', sorted);
      const newestBySubtree: any = _.uniqBy(sorted, "subtree");
      console.log('newestBySubtree', newestBySubtree);

      return update(state, { contents: { $set: newestBySubtree } });
    case IdeaTypes.SET_CURRENT_CONTENT:
      const current = action.current;
      return update(state, { getcontent: { current: { $set: current } } });
    case IdeaTypes.SET_PUBLIC_CURRENT_CONTENT:
      const publiccurrent = action.publiccurrent;
      return update(state, { getpubliccontent: { publiccurrent: { $set: publiccurrent } } });
    case IdeaTypes.ADD_CONTENT:
      const mut: any = action.mutationParams
      return update(state, { mutation: { mutation: { $set: mut } } })
    case IdeaTypes.ADD_CONTENT_OK:
      const ok: boolean = action.ok;
      return update(state, { mutation: { ok: { $set: ok } } });
    case IdeaTypes.ADD_CONTENT_NOTOK:
      const nok: boolean = action.notok;
      return update(state, { uploading: { $set: false }, mutation: { nok: { $set: nok } } });
    case IdeaTypes.UPLOADING:
      return update(state, { uploading: { $set: true } });
    case IdeaTypes.UPLOAD_UPDATE_PROGRESS:
      return update(state, { update_progress: { $set: action.value } })
    case IdeaTypes.UPLOAD_SUCCESS:
      return update(state, { uploading: { $set: false } });
    case IdeaTypes.ABORT_UPLOAD:
      return update(state, { uploading: { $set: false } });
    case IdeaTypes.SET_FORMDATA:
      const params = action.params;
      return update(state, { newcontent: { formdata: { $set: params } } })
    case IdeaTypes.UNSET_FORMDATA:
      return update(state, { newcontent: { formdata: { $set: {} } } });
    case IdeaTypes.SET_APP_STATE:
      let prev: IdeaTypes.AppState = state.appstate.current;
      return update(state, {

        appstate: { current: { $set: action.appstate }, prev: { $set: prev }, params: { $set: action.params } }
      });
    case IdeaTypes.SET_ERROR:
      let code = action.code;
      return update(state, {
        apperror: {
          code: { $set: code },
          returnstate: { $set: action.returnstate || IdeaTypes.AppState.IDLE },
          params: { $set: action.params }
        }
      });
    case IdeaTypes.SET_FORM_ERRORS:
      let error = action.errors;
      return update(state, { formErrors: { $set: error } })
    case IdeaTypes.SET_APP_STATE_CLIPBOARD:
      let clipb = action.clipboardData;
      return update(state, { appstate: { clipboardData: { $set: clipb } } });

    case IdeaTypes.DID_SET_LDJSON_FROM_URL:
      return update(state, { getcontent: { current: { LDURL: { $set: action.url }, LDJSON: { $set: JSON.stringify(action.json) } } } });
    case IdeaTypes.DID_SET_LDJSON_FROM_ID:
      let url = `${config.learningDesignerConfiguration.jsonUrlPrefix}${action.id}`
      return update(state, { getcontent: { current: { LDURL: { $set: url }, LDJSON: { $set: JSON.stringify(action.json) } } } });
    default:
      return state
  }

}

export default contentReducer;