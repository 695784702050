import API, { graphqlOperation } from "@aws-amplify/api";
import listContents from '../queries/listContents';
let config:any = {}
if (process.env.REACT_APP_STAGE === 'PROD') {
    config = require('../config-prod.json');
} else {
    config = require('../config-dev.json');
}



export const doListContents = async (term: any) => {
    API.configure(config.authentication.appsyncAPI_KEY);

    return await API.graphql(graphqlOperation(listContents(term)));
}