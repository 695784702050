import { put, call, take, takeLatest } from 'redux-saga/effects';
import * as IdeaSearchActions from '../actions/search'
import * as IdeaSearchTypes from '../constants/search'
import API, { graphqlOperation } from '@aws-amplify/api';
import * as SearchApi from '../../api/search'

let config = {}
if (process.env.REACT_APP_STAGE === 'PROD') {
    config = require('../../config-prod.json');
} else {
    config = require('../../config-dev.json');
}


export function* willSearchAuthSagas() {
    yield takeLatest(IdeaSearchTypes.WILL_SEARCH_TERM, willSearchTerm);
}
export function* willSearchTerm(action: IdeaSearchTypes.WillSearchTerm) {
    let result = yield call(SearchApi.doListContents, action.searchTerm);
    yield put(IdeaSearchActions.didSearchTerm(result.data.listContents));
}
