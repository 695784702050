import { createStore, combineReducers, applyMiddleware } from 'redux';
import { authReducer } from './auth'
import { contentReducer } from './content'
import { searchReducer } from './search'
import { connectRouter } from 'connected-react-router';

// export const rootReducer = combineReducers < any | undefined> ({
//   auth: authReducer,
//   content: contentReducer,
//   search: searchReducer
// });

export const createRootReducer = (history: any) =>
  combineReducers<any | undefined>({
    router: connectRouter(history),
    auth: authReducer,
    content: contentReducer,
    search: searchReducer
  });
// export default rootReducer;