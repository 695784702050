export default `

mutation addContent(
    $content: String,
    $title: String,
    $summary: String,
    $subject: [SubjectElementInput],
    $grade: Grade,
    $ideaThematic: [IDEAThematic], 
    $resources: [Resource],
    $s3URL: String,
    $parent: String 
    $subtree: String 
    $rootcontent: String 
    $LDJSON: String
    $LDURL: String
    $state: ContentState
    $insertiontype : InsertionType
) {
    addContent(
     content: $content,
     title: $title,
     summary: $summary, 
     subject: $subject, 
     grade: $grade, 
     ideaThematic: $ideaThematic, 
     resources: $resources,
     s3URL: $s3URL,
     parent: $parent,
     subtree: $subtree,
     rootcontent: $rootcontent,
     state: $state,
     insertiontype: $insertiontype,
     LDJSON: $LDJSON,
     LDURL: $LDURL )
    {
            user
            content
            summary
            resources
    }
}
`