import React from 'react';
import { RXDashboard } from '@radixnpm/rx-dashboard-test';
import logoLoad from './assets/logoidea.png';
import { Provider } from "react-redux";
import './App.css'
import { store } from "./redux/store";

import { Badge } from 'reactstrap';

import { library } from '@fortawesome/fontawesome-svg-core'
import { faCheckSquare, faCoffee, faTrash, faLink, faCopy } from '@fortawesome/free-solid-svg-icons'

import { IdeaModalError } from './components/modals'
// import {UploadingModal} from './components/modals'
import { ConnectedRouter } from 'connected-react-router';

import { history } from './redux/store/index'

library.add(faCheckSquare, faCoffee, faTrash, faLink, faCopy);
const appLoader = (page: any) => import(`./pages/${page}`);
const publicAppLoader = (page) => import(`./pages/public/${page}`)

const loaders = {
  "app": appLoader,
  "public": publicAppLoader

};
const logo = import('./assets/idealogolight.png');
const logoMinimized = import('./assets/logoMinimized.png');


const loading = () => <div style={{
  width: "100%", position: "relative", textAlign: "center", height: '80vh',
  display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center'
}}
><img src={logoLoad} alt="Logo" /></div>;
console.log('STAGE', process.env.REACT_APP_STAGE);

let config = {}
if (process.env.REACT_APP_STAGE === 'PROD') {
  config = require('./config-prod.json');
} else {
  config = require('./config-dev.json');
}
console.log('STAGE', process.env.REACT_APP_STAGE, config);

const App = (props: any) => {
  return (
    <Provider store={store as any}>
      <RXDashboard
        history={history}
        routerComponent={ConnectedRouter}
        loading={loading} logo={logo} logoMinimized={logoMinimized} configuration={config} loaders={loaders} className="aside-menu-fixed" />
      <IdeaModalError />
      {(process.env.REACT_APP_STAGE === "DEV") && <h1><Badge>DEV</Badge></h1>}
    </Provider>
  )
}

export default App;
