
import { put, all, call } from 'redux-saga/effects';

import { willLoadAuthSagas } from './auth'
import { willLoadContentSagas } from './content'
import { willSearchAuthSagas } from './search';


export default function* rootSaga() {
    yield all([
        willLoadAuthSagas(),
        willLoadContentSagas(),
        willSearchAuthSagas()
    ]);
 }