export const cf_strings = () => {
    return {
        formTitleAdd: "Aggiungi Risorsa",
        formTitle: "",
        addModalErrorBody: 'Errore!',
        addModalErrorHeader: 'Tutti i campi del form devono essere presenti',
        uploadingMessage: 'Upload in corso...',
        contentAddedModalBody: 'Contenuto aggiunto con successo',
        contentAddedModalHeader: 'Ok',
        contentEditingHeader: 'Contenuto da modificare:',
        contentImportingFromIdHeader: 'Contenuto base:',
    }
}
export const profile_strings = (params) => {
    if (!params) {
        params = {};
    }
    return {
        profileNotFoundError: `Errore durante il caricamento del profilo`,
        profileName: `${params.name} (${params.username})`,
        profileUsername: ``,
        profileEmail: `Email: ${params.email}`,
        profileSub: `Il tuo ID sulla piattaforma è `
    }
}
export const cl_strings = (_params) => {
    return {
        contentLoaded: "Contenuti caricati correttamente",
        contentLoadedError: "Errore durante il caricamento dei contenuti, prova ad effettuare il login",
        contentIdBadgeCopied: `Copiato`,
        buttonImportFromJSON: 'Importa contenuto da Learning Designer',
        subject: {
            "IENUM_00AA": {"label": "00AA - Infanzia: posto comune", verbose : "00AA - Infanzia: posto comune", "ideab3": "IENUM_00AA"},
        "IENUM_00EE": {"label": "00EE - Primaria: posto comune", verbose : "00EE - Primaria: posto comune", "ideab3": "IENUM_00EE"},
        "IENUM_A01": {"label": "A-01 - Arte e immagine nella scuola secondaria di I grado", verbose : "A-01 - Arte e immagine nella scuola secondaria di I grado", "ideab3": "IENUM_A01"},
        "IENUM_A02": {"label": "A-02 - Design dei metalli, dell'oreficeria, delle pietre dure e delle gemme", verbose : "A-02 - Design dei metalli, dell'oreficeria, delle pietre dure e delle gemme", "ideab3": "IENUM_A02"},
        "IENUM_A03": {"label": "A-03 - Design della ceramica", verbose : "A-03 - Design della ceramica", "ideab3": "IENUM_A03"},
        "IENUM_A04": {"label": "A-04 - Design del libro", verbose : "A-04 - Design del libro", "ideab3": "IENUM_A04"},
        "IENUM_A05": {"label": "A-05 - Design del tessuto e della moda", verbose : "A-05 - Design del tessuto e della moda", "ideab3": "IENUM_A05"},
        "IENUM_A06": {"label": "A-06 - Design del vetro", verbose : "A-06 - Design del vetro", "ideab3": "IENUM_A06"},
        "IENUM_A07": {"label": "A-07 - Discipline Audiovisive", verbose : "A-07 - Discipline Audiovisive", "ideab3": "IENUM_A07"},
        "IENUM_A08": {"label": "A-08 - Discipline geometriche, architettura, design d'arredamento e scenotecnica", verbose : "A-08 - Discipline geometriche, architettura, design d'arredamento e scenotecnica", "ideab3": "IENUM_A08"},
        "IENUM_A09": {"label": "A-09 - Discipline grafiche, pittoriche e scenografiche", verbose : "A-09 - Discipline grafiche, pittoriche e scenografiche", "ideab3": "IENUM_A09"},
        "IENUM_A10": {"label": "A-10 - Discipline grafico-pubblicitarie", verbose : "A-10 - Discipline grafico-pubblicitarie", "ideab3": "IENUM_A10"},
        "IENUM_A11": {"label": "A-11 - Discipline letterarie e latino", verbose : "A-11 - Discipline letterarie e latino", "ideab3": "IENUM_A11"},
        "IENUM_A12": {"label": "A-12 - Discipline letterarie negli istituti di istruzione secondaria di II grado", verbose : "A-12 - Discipline letterarie negli istituti di istruzione secondaria di II grado", "ideab3": "IENUM_A12"},
        "IENUM_A13": {"label": "A-13 - Discipline letterarie, latino e greco", verbose : "A-13 - Discipline letterarie, latino e greco", "ideab3": "IENUM_A13"},
        "IENUM_A14": {"label": "A-14 - Discipline plastiche, scultoree e scenoplastiche", verbose : "A-14 - Discipline plastiche, scultoree e scenoplastiche", "ideab3": "IENUM_A14"},
        "IENUM_A15": {"label": "A-15 - Discipline sanitarie", verbose : "A-15 - Discipline sanitarie", "ideab3": "IENUM_A15"},
        "IENUM_A16": {"label": "A-16 - Disegno artistico e modellazione odontotecnica", verbose : "A-16 - Disegno artistico e modellazione odontotecnica", "ideab3": "IENUM_A16"},
        "IENUM_A17": {"label": "A-17 - Disegno e storia dell'arte negli istituti di istruzione secondaria di II grado", verbose : "A-17 - Disegno e storia dell'arte negli istituti di istruzione secondaria di II grado", "ideab3": "IENUM_A17"},
        "IENUM_A18": {"label": "A-18 - Filosofia e Scienze umane", verbose : "A-18 - Filosofia e Scienze umane", "ideab3": "IENUM_A18"},
        "IENUM_A19": {"label": "A-19 - Filosofia e Storia", verbose : "A-19 - Filosofia e Storia", "ideab3": "IENUM_A19"},
        "IENUM_A20": {"label": "A-20 - Fisica", verbose : "A-20 - Fisica", "ideab3": "IENUM_A20"},
        "IENUM_A21": {"label": "A-21 - Geografia", verbose : "A-21 - Geografia", "ideab3": "IENUM_A21"},
        "IENUM_A22": {"label": "A-22 - Italiano, storia, geografia, nella scuola secondaria di I grado", verbose : "A-22 - Italiano, storia, geografia, nella scuola secondaria di I grado", "ideab3": "IENUM_A22"},
        "IENUM_A23": {"label": "A-23 - Lingua italiana per discenti di lingua straniera (alloglotti)", verbose : "A-23 - Lingua italiana per discenti di lingua straniera (alloglotti)", "ideab3": "IENUM_A23"},
        "IENUM_A24": {"label": "A-24 - Lingue e culture straniere negli istituti di istruzione secondaria di II grado", verbose : "A-24 - Lingue e culture straniere negli istituti di istruzione secondaria di II grado", "ideab3": "IENUM_A24"},
        "IENUM_A25": {"label": "A-25 - Lingua inglese e seconda lingua comunitaria nella scuola secondaria di primo grado", verbose : "A-25 - Lingua inglese e seconda lingua comunitaria nella scuola secondaria di primo grado", "ideab3": "IENUM_A25"},
        "IENUM_A26": {"label": "A-26 - Matematica", verbose : "A-26 - Matematica", "ideab3": "IENUM_A26"},
        "IENUM_A27": {"label": "A-27 - Matematica e Fisica", verbose : "A-27 - Matematica e Fisica", "ideab3": "IENUM_A27"},
        "IENUM_A28": {"label": "A-28 - Matematica e scienze", verbose : "A-28 - Matematica e scienze", "ideab3": "IENUM_A28"},
        "IENUM_A29": {"label": "A-29 - Musica negli istituti di istruzione secondaria di II grado", verbose : "A-29 - Musica negli istituti di istruzione secondaria di II grado", "ideab3": "IENUM_A29"},
        "IENUM_A30": {"label": "A-30 - Musica nella scuola secondaria di I grado", verbose : "A-30 - Musica nella scuola secondaria di I grado", "ideab3": "IENUM_A30"},
        "IENUM_A31": {"label": "A-31 - Scienze degli alimenti", verbose : "A-31 - Scienze degli alimenti", "ideab3": "IENUM_A31"},
        "IENUM_A32": {"label": "A-32 - Scienze della geologia e della mineralogia", verbose : "A-32 - Scienze della geologia e della mineralogia", "ideab3": "IENUM_A32"},
        "IENUM_A33": {"label": "A-33 - Scienze e tecnologie aeronautiche", verbose : "A-33 - Scienze e tecnologie aeronautiche", "ideab3": "IENUM_A33"},
        "IENUM_A34": {"label": "A-34 - Scienze e tecnologie chimiche", verbose : "A-34 - Scienze e tecnologie chimiche", "ideab3": "IENUM_A34"},
        "IENUM_A35": {"label": "A-35 - Scienze e tecnologie della calzatura e della moda", verbose : "A-35 - Scienze e tecnologie della calzatura e della moda", "ideab3": "IENUM_A35"},
        "IENUM_A36": {"label": "A-36 - Scienze e tecnologie della logistica", verbose : "A-36 - Scienze e tecnologie della logistica", "ideab3": "IENUM_A36"},
        "IENUM_A37": {"label": "A-37 - Scienze e tecnologie delle costruzioni, tecnologie e tecniche di rappresentazione grafica", verbose : "A-37 - Scienze e tecnologie delle costruzioni, tecnologie e tecniche di rappresentazione grafica", "ideab3": "IENUM_A37"},
        "IENUM_A38": {"label": "A-38 - Scienze e tecnologie delle costruzioni aeronautiche", verbose : "A-38 - Scienze e tecnologie delle costruzioni aeronautiche", "ideab3": "IENUM_A38"},
        "IENUM_A39": {"label": "A-39 - Scienze e tecnologie delle costruzioni navali", verbose : "A-39 - Scienze e tecnologie delle costruzioni navali", "ideab3": "IENUM_A39"},
        "IENUM_A40": {"label": "A-40 - Scienze e tecnologie elettriche ed elettroniche", verbose : "A-40 - Scienze e tecnologie elettriche ed elettroniche", "ideab3": "IENUM_A40"},
        "IENUM_A41": {"label": "A-41 - Scienze e tecnologie informatiche", verbose : "A-41 - Scienze e tecnologie informatiche", "ideab3": "IENUM_A41"},
        "IENUM_A42": {"label": "A-42 - Scienze e tecnologie meccaniche", verbose : "A-42 - Scienze e tecnologie meccaniche", "ideab3": "IENUM_A42"},
        "IENUM_A43": {"label": "A-43 - Scienze e tecnologie nautiche", verbose : "A-43 - Scienze e tecnologie nautiche", "ideab3": "IENUM_A43"},
        "IENUM_A44": {"label": "A-44 - Scienze e tecnologie tessili, dell'abbigliamento e della moda", verbose : "A-44 - Scienze e tecnologie tessili, dell'abbigliamento e della moda", "ideab3": "IENUM_A44"},
        "IENUM_A45": {"label": "A-45 - Scienze economico-aziendali", verbose : "A-45 - Scienze economico-aziendali", "ideab3": "IENUM_A45"},
        "IENUM_A46": {"label": "A-46 - Scienze giuridico-economiche", verbose : "A-46 - Scienze giuridico-economiche", "ideab3": "IENUM_A46"},
        "IENUM_A47": {"label": "A-47 - Scienze matematiche applicate", verbose : "A-47 - Scienze matematiche applicate", "ideab3": "IENUM_A47"},
        "IENUM_A48": {"label": "A-48 - Scienze motorie e sportive negli istituti di istruzione secondaria di II grado", verbose : "A-48 - Scienze motorie e sportive negli istituti di istruzione secondaria di II grado", "ideab3": "IENUM_A48"},
        "IENUM_A49": {"label": "A-49 - Scienze motorie e sportive nella scuola secondaria di I grado", verbose : "A-49 - Scienze motorie e sportive nella scuola secondaria di I grado", "ideab3": "IENUM_A49"},
        "IENUM_A50": {"label": "A-50 - Scienze naturali, chimiche e biologiche", verbose : "A-50 - Scienze naturali, chimiche e biologiche", "ideab3": "IENUM_A50"},
        "IENUM_A51": {"label": "A-51 - Scienze, tecnologie e tecniche agrarie", verbose : "A-51 - Scienze, tecnologie e tecniche agrarie", "ideab3": "IENUM_A51"},
        "IENUM_A52": {"label": "A-52 - Scienze, tecnologie e tecniche di produzioni animali", verbose : "A-52 - Scienze, tecnologie e tecniche di produzioni animali", "ideab3": "IENUM_A52"},
        "IENUM_A53": {"label": "A-53 - Storia della musica", verbose : "A-53 - Storia della musica", "ideab3": "IENUM_A53"},
        "IENUM_A54": {"label": "A-54 - Storia dell'arte", verbose : "A-54 - Storia dell'arte", "ideab3": "IENUM_A54"},
        "IENUM_A55": {"label": "A-55 - Strumento musicale negli istituti di istruzione superiore di II grado", verbose : "A-55 - Strumento musicale negli istituti di istruzione superiore di II grado", "ideab3": "IENUM_A55"},
        "IENUM_A56": {"label": "A-56 - Strumento musicale nella scuola secondaria di I grado", verbose : "A-56 - Strumento musicale nella scuola secondaria di I grado", "ideab3": "IENUM_A56"},
        "IENUM_A57": {"label": "A-57 - Tecnica della danza classica", verbose : "A-57 - Tecnica della danza classica", "ideab3": "IENUM_A57"},
        "IENUM_A58": {"label": "A-58 - Tecnica della danza contemporanea", verbose : "A-58 - Tecnica della danza contemporanea", "ideab3": "IENUM_A58"},
        "IENUM_A59": {"label": "A-59 - Tecniche di accompagnamento alla danza e teoria e pratica musicale per la danza", verbose : "A-59 - Tecniche di accompagnamento alla danza e teoria e pratica musicale per la danza", "ideab3": "IENUM_A59"},
        "IENUM_A60": {"label": "A-60 - Tecnologia nella scuola secondaria di I grado", verbose : "A-60 - Tecnologia nella scuola secondaria di I grado", "ideab3": "IENUM_A60"},
        "IENUM_A61": {"label": "A-61 - Tecnologie e tecniche delle comunicazioni multimediali", verbose : "A-61 - Tecnologie e tecniche delle comunicazioni multimediali", "ideab3": "IENUM_A61"},
        "IENUM_A62": {"label": "A-62 - Tecnologie e tecniche per la grafica", verbose : "A-62 - Tecnologie e tecniche per la grafica", "ideab3": "IENUM_A62"},
        "IENUM_A63": {"label": "A-63 - Tecnologie musicali", verbose : "A-63 - Tecnologie musicali", "ideab3": "IENUM_A63"},
        "IENUM_A64": {"label": "A-64 - Teoria, analisi e composizione", verbose : "A-64 - Teoria, analisi e composizione", "ideab3": "IENUM_A64"},
        "IENUM_A65": {"label": "A-65 - Teoria e tecnica della comunicazione", verbose : "A-65 - Teoria e tecnica della comunicazione", "ideab3": "IENUM_A65"},
        "IENUM_A66": {"label": "A-66 - Trattamento testi, dati ed applicazioni. Informatica (ad esaurimento)", verbose : "A-66 - Trattamento testi, dati ed applicazioni. Informatica (ad esaurimento)", "ideab3": "IENUM_A66"},
        "IENUM_A70": {"label": "A-70 - Italiano, storia ed educazione civica, geografia, nella scuola secondaria di I grado con lingua di insegnamento slovena", verbose : "A-70 - Italiano, storia ed educazione civica, geografia, nella scuola secondaria di I grado con lingua di insegnamento slovena", "ideab3": "IENUM_A70"},
        "IENUM_A71": {"label": "A-71 - Sloveno, storia ed educazione civica, geografia, nella scuola secondaria di I grado con lingua di insegnamento slovena o bilingue del Friuli Venezia Giulia", verbose : "A-71 - Sloveno, storia ed educazione civica, geografia, nella scuola secondaria di I grado con lingua di insegnamento slovena o bilingue del Friuli Venezia Giulia", "ideab3": "IENUM_A71"},
        "IENUM_A72": {"label": "A-72 - Discipline letterarie (Italiano seconda lingua) negli istituti di istruzione secondaria di II grado con lingua di insegnamento slovena o bilingue del Friuli Venezia Giulia", verbose : "A-72 - Discipline letterarie (Italiano seconda lingua) negli istituti di istruzione secondaria di II grado con lingua di insegnamento slovena o bilingue del Friuli Venezia Giulia", "ideab3": "IENUM_A72"},
        "IENUM_A73": {"label": "A-73 - Discipline letterarie negli istituti di istruzione secondaria di II grado con lingua di insegnamento slovena o bilingue del Friuli Venezia Giulia", verbose : "A-73 - Discipline letterarie negli istituti di istruzione secondaria di II grado con lingua di insegnamento slovena o bilingue del Friuli Venezia Giulia", "ideab3": "IENUM_A73"},
        "IENUM_A74": {"label": "A-74 - Discipline letterarie e latino con lingua di insegnamento slovena", verbose : "A-74 - Discipline letterarie e latino con lingua di insegnamento slovena", "ideab3": "IENUM_A74"},
        "IENUM_A75": {"label": "A-75 - Discipline letterarie, latino e greco con lingua di insegnamento slovena", verbose : "A-75 - Discipline letterarie, latino e greco con lingua di insegnamento slovena", "ideab3": "IENUM_A75"},
        "IENUM_A76": {"label": "A-76 - Trattamento testi, dati ed applicazioni, Informatica negli istituti professionali con lingua di insegnamento slovena", verbose : "A-76 - Trattamento testi, dati ed applicazioni, Informatica negli istituti professionali con lingua di insegnamento slovena", "ideab3": "IENUM_A76"},
        "IENUM_A77": {"label": "A-77 - Lingua e cultura ladina, storia ed educazione civica, geografia, nella scuola secondaria di I grado con lingua di insegnamento ladina", verbose : "A-77 - Lingua e cultura ladina, storia ed educazione civica, geografia, nella scuola secondaria di I grado con lingua di insegnamento ladina", "ideab3": "IENUM_A77"},
        "IENUM_A78": {"label": "A-78 - Italiano (seconda lingua), storia ed educazione civica, geografia, nella scuola secondaria di I grado con lingua di insegnamento tedesca", verbose : "A-78 - Italiano (seconda lingua), storia ed educazione civica, geografia, nella scuola secondaria di I grado con lingua di insegnamento tedesca", "ideab3": "IENUM_A78"},
        "IENUM_A79": {"label": "A-79 - Discipline letterarie (italiano seconda lingua) negli istituti di istruzione secondaria di II grado in lingua tedesca", verbose : "A-79 - Discipline letterarie (italiano seconda lingua) negli istituti di istruzione secondaria di II grado in lingua tedesca", "ideab3": "IENUM_A79"},
        "IENUM_A80": {"label": "A-80 - Discipline letterarie negli istituti di istruzione secondaria di II grado in lingua tedesca e con lingua di insegnamento tedesca delle localit� ladine", verbose : "A-80 - Discipline letterarie negli istituti di istruzione secondaria di II grado in lingua tedesca e con lingua di insegnamento tedesca delle localit� ladine", "ideab3": "IENUM_A80"},
        "IENUM_A81": {"label": "A-81 - Discipline letterarie e latino nei licei in lingua tedesca e con lingua di insegnamento, tedesca delle localit� ladine", verbose : "A-81 - Discipline letterarie e latino nei licei in lingua tedesca e con lingua di insegnamento, tedesca delle localit� ladine", "ideab3": "IENUM_A81"},
        "IENUM_A82": {"label": "A-82 - Discipline letterarie, latino e greco nel liceo classico in lingua tedesca e con lingua di insegnamento tedesca delle localit� ladine", verbose : "A-82 - Discipline letterarie, latino e greco nel liceo classico in lingua tedesca e con lingua di insegnamento tedesca delle localit� ladine", "ideab3": "IENUM_A82"},
        "IENUM_A83": {"label": "A-83 - Discipline letterarie (tedesco seconda lingua) negli istituti di istruzione secondaria di II grado in lingua italiana della provincia di Bolzano", verbose : "A-83 - Discipline letterarie (tedesco seconda lingua) negli istituti di istruzione secondaria di II grado in lingua italiana della provincia di Bolzano", "ideab3": "IENUM_A83"},
        "IENUM_A84": {"label": "A-84 - Tedesco (seconda lingua), storia ed educazione civica, geografia, nella scuola secondaria di I grado con lingua di insegnamento italiana della provincia di Bolzano", verbose : "A-84 - Tedesco (seconda lingua), storia ed educazione civica, geografia, nella scuola secondaria di I grado con lingua di insegnamento italiana della provincia di Bolzano", "ideab3": "IENUM_A84"},
        "IENUM_A85": {"label": "A-85 - Tedesco storia ed educazione civica, geografia, nella scuola secondaria di I grado in lingua tedesca e con lingua di insegnamento tedesca", verbose : "A-85 - Tedesco storia ed educazione civica, geografia, nella scuola secondaria di I grado in lingua tedesca e con lingua di insegnamento tedesca", "ideab3": "IENUM_A85"},
        "IENUM_A86": {"label": "A-86 - Trattamento testi, dati ed applicazioni, Informatica negli istituti professionali in lingua tedesca e con lingua di insegnamento tedesca", verbose : "A-86 - Trattamento testi, dati ed applicazioni, Informatica negli istituti professionali in lingua tedesca e con lingua di insegnamento tedesca", "ideab3": "IENUM_A86"},
        "IENUM_AD00": {"label": "AD00 - Sostegno Scuola Media: area comune", verbose : "AD00 - Sostegno Scuola Media: area comune", "ideab3": "IENUM_AD00"},
        "IENUM_AD0D": {"label": "AD0D - Sostegno Scuola Infanzia: area comune", verbose : "AD0D - Sostegno Scuola Infanzia: area comune", "ideab3": "IENUM_AD0D"},
        "IENUM_AD0J": {"label": "AD0J - Sostegno Scuola Elementare: area comune", verbose : "AD0J - Sostegno Scuola Elementare: area comune", "ideab3": "IENUM_AD0J"},
        "IENUM_ADSS": {"label": "ADSS - Sostegno Scuola Superiore", verbose : "ADSS - Sostegno Scuola Superiore", "ideab3": "IENUM_ADSS"},
        "IENUM_B01": {"label": "B-01 - Attivit� pratiche speciali", verbose : "B-01 - Attivit� pratiche speciali", "ideab3": "IENUM_B01"},
        "IENUM_B02": {"label": "B-02 - Conversazione in lingua straniera", verbose : "B-02 - Conversazione in lingua straniera", "ideab3": "IENUM_B02"},
        "IENUM_B03": {"label": "B-03 - Laboratori di Fisica", verbose : "B-03 - Laboratori di Fisica", "ideab3": "IENUM_B03"},
        "IENUM_B04": {"label": "B-04 - Laboratori di liuteria", verbose : "B-04 - Laboratori di liuteria", "ideab3": "IENUM_B04"},
        "IENUM_B05": {"label": "B-05 - Laboratorio di logistica", verbose : "B-05 - Laboratorio di logistica", "ideab3": "IENUM_B05"},
        "IENUM_B06": {"label": "B-06 - Laboratorio di odontotecnica", verbose : "B-06 - Laboratorio di odontotecnica", "ideab3": "IENUM_B06"},
        "IENUM_B07": {"label": "B-07 - Laboratorio di ottica", verbose : "B-07 - Laboratorio di ottica", "ideab3": "IENUM_B07"},
        "IENUM_B08": {"label": "B-08 - Laboratori di produzioni industriali ed artigianali della ceramica", verbose : "B-08 - Laboratori di produzioni industriali ed artigianali della ceramica", "ideab3": "IENUM_B08"},
        "IENUM_B09": {"label": "B-09 - Laboratori di scienze e tecnologie aeronautiche", verbose : "B-09 - Laboratori di scienze e tecnologie aeronautiche", "ideab3": "IENUM_B09"},
        "IENUM_B10": {"label": "B-10 - Laboratori di scienze e tecnologie delle costruzioni aeronautiche", verbose : "B-10 - Laboratori di scienze e tecnologie delle costruzioni aeronautiche", "ideab3": "IENUM_B10"},
        "IENUM_B11": {"label": "B-11 - Laboratori di scienze e tecnologie agrarie", verbose : "B-11 - Laboratori di scienze e tecnologie agrarie", "ideab3": "IENUM_B11"},
        "IENUM_B12": {"label": "B-12 - Laboratori di scienze e tecnologie chimiche e microbiologiche", verbose : "B-12 - Laboratori di scienze e tecnologie chimiche e microbiologiche", "ideab3": "IENUM_B12"},
        "IENUM_B13": {"label": "B-13 - Laboratori di scienze e tecnologie della calzatura e della moda", verbose : "B-13 - Laboratori di scienze e tecnologie della calzatura e della moda", "ideab3": "IENUM_B13"},
        "IENUM_B14": {"label": "B-14 - Laboratori di scienze e tecnologie delle costruzioni", verbose : "B-14 - Laboratori di scienze e tecnologie delle costruzioni", "ideab3": "IENUM_B14"},
        "IENUM_B15": {"label": "B-15 - Laboratori di scienze e tecnologie elettriche ed elettroniche", verbose : "B-15 - Laboratori di scienze e tecnologie elettriche ed elettroniche", "ideab3": "IENUM_B15"},
        "IENUM_B16": {"label": "B-16 - Laboratori di scienze e tecnologie informatiche", verbose : "B-16 - Laboratori di scienze e tecnologie informatiche", "ideab3": "IENUM_B16"},
        "IENUM_B17": {"label": "B-17 - Laboratori di scienze e tecnologie meccaniche", verbose : "B-17 - Laboratori di scienze e tecnologie meccaniche", "ideab3": "IENUM_B17"},
        "IENUM_B18": {"label": "B-18 - Laboratori di scienze e tecnologie tessili, dell'abbigliamento e della moda", verbose : "B-18 - Laboratori di scienze e tecnologie tessili, dell'abbigliamento e della moda", "ideab3": "IENUM_B18"},
        "IENUM_B19": {"label": "B-19 - Laboratori di servizi di ricettivit� alberghiera", verbose : "B-19 - Laboratori di servizi di ricettivit� alberghiera", "ideab3": "IENUM_B19"},
        "IENUM_B20": {"label": "B-20 - Laboratori di servizi enogastronomici, settore cucina", verbose : "B-20 - Laboratori di servizi enogastronomici, settore cucina", "ideab3": "IENUM_B20"},
        "IENUM_B21": {"label": "B-21 - Laboratori di servizi enogastronomici, settore sala e vendita", verbose : "B-21 - Laboratori di servizi enogastronomici, settore sala e vendita", "ideab3": "IENUM_B21"},
        "IENUM_B22": {"label": "B-22 - Laboratori di tecnologie e tecniche delle comunicazioni multimediali", verbose : "B-22 - Laboratori di tecnologie e tecniche delle comunicazioni multimediali", "ideab3": "IENUM_B22"},
        "IENUM_B23": {"label": "B-23 - Laboratori per i servizi socio�sanitari", verbose : "B-23 - Laboratori per i servizi socio�sanitari", "ideab3": "IENUM_B23"},
        "IENUM_B24": {"label": "B-24 - Laboratorio di scienze e tecnologie nautiche", verbose : "B-24 - Laboratorio di scienze e tecnologie nautiche", "ideab3": "IENUM_B24"},
        "IENUM_B25": {"label": "B-25 - Laboratorio di scienze e tecnologie delle costruzioni navali", verbose : "B-25 - Laboratorio di scienze e tecnologie delle costruzioni navali", "ideab3": "IENUM_B25"},
        "IENUM_B26": {"label": "B-26 - Laboratorio di tecnologie del legno", verbose : "B-26 - Laboratorio di tecnologie del legno", "ideab3": "IENUM_B26"},
        "IENUM_B27": {"label": "B-27 - Laboratorio di tecnologie del marmo", verbose : "B-27 - Laboratorio di tecnologie del marmo", "ideab3": "IENUM_B27"},
        "IENUM_B28": {"label": "B-28 - Laboratorio di tecnologie orafe", verbose : "B-28 - Laboratorio di tecnologie orafe", "ideab3": "IENUM_B28"},
        "IENUM_B29": {"label": "B-29 - Gabinetto fisioterapico", verbose : "B-29 - Gabinetto fisioterapico", "ideab3": "IENUM_B29"},
        "IENUM_B30": {"label": "B-30 - Addetto all'ufficio tecnico", verbose : "B-30 - Addetto all'ufficio tecnico", "ideab3": "IENUM_B30"},
        "IENUM_B31": {"label": "B-31 - Esercitazioni pratiche per centralinisti telefonici", verbose : "B-31 - Esercitazioni pratiche per centralinisti telefonici", "ideab3": "IENUM_B31"},
        "IENUM_B32": {"label": "B-32 - Esercitazioni di pratica professionale", verbose : "B-32 - Esercitazioni di pratica professionale", "ideab3": "IENUM_B32"},
        "IENUM_PP": {"label": "PP - Educatore", verbose : "PP - Educatore", "ideab3": "IENUM_PP"},
        "IENUM_RLEE": {"label": "RLEE - Religione scuola primaria", verbose : "RLEE - Religione scuola primaria", "ideab3": "IENUM_RLEE"},
        "IENUM_RLMM": {"label": "RLMM - Religione scuola media", verbose : "RLMM - Religione scuola media", "ideab3": "IENUM_RLMM"},
        "IENUM_RLSS": {"label": "RLSS - Religione scuola superiore", verbose : "RLSS - Religione scuola superiore", "ideab3": "IENUM_RLSS"}
        }
        
        ,
        ideaThematic: {
            ScuoleSenzaPareti: { label: "SSP", verbose: "Scuola Senza Pareti", ideab3: "ScuoleSenzaPareti" },
            PensieroComputazionale: { label: "PC-IOT", verbose: "Pensiero Computazionale", ideab3: "PensieroComputazionale" },
            IntelligenzaArtificiale: { label: "IA", verbose: "Intelligenza Artificiale", ideab3: "IntelligenzaArtificiale" },

        },
        resources: {
            Lezione: { "verbose": "Lezione", "label": "Lezione", "ideab3": "Lezione" },
            Esercitazione: { "verbose": "Esercitazione", "label": "Esercitazione", "ideab3": "Esercitazione" },
            Compito: { "verbose": "Compito", "label": "Compito", "ideab3": "Compito" },
            Audio: { "verbose": "Audio", "label": "Audio ", "ideab3": "Audio" },
            Video: { "verbose": "Video", "label": "Video", "ideab3": "Video" },
            Esperimento: { "verbose": "Esperimento", "label": "Esperimento", "ideab3": "Esperimento" },
            Documento: { "verbose": "Documento", "label": "Documento", "ideab3": "Documento" }
        },
        gradeElements: {
            SecondariaPrimoGradoI: { label: "P I", verbose: "Primo Grado: I", ideab3: "SecondariaPrimoGradoI" },
            SecondariaPrimoGradoII: { label: "P II", verbose: "Primo Grado: II", ideab3: "SecondariaPrimoGradoII" },
            SecondariaPrimoGradoIII: { label: "P III", verbose: "Primo Grado: III", ideab3: "SecondariaPrimoGradoIII" },
            SecondariaSecondoGradoI: { label: "S I", verbose: "Secondo Grado: I", ideab3: "SecondariaSecondoGradoI" },
            SecondariaSecondoGradoII: { label: "S II", verbose: "Secondo Grado: II", ideab3: "SecondariaSecondoGradoII" },
            SecondariaSecondoGradoIII: { label: "S III", verbose: "Secondo Grado: III", ideab3: "SecondariaSecondoGradoIII" },
            SecondariaSecondoGradoIV: { label: "S IV", verbose: "Secondo Grado: IV", ideab3: "SecondariaSecondoGradoIV" },
            SecondariaSecondoGradoV: { label: "S V", verbose: "Secondo Grado: V", ideab3: "SecondariaSecondoGradoV" },
        }
    }
}

export const modals_strings = (params) => {
    if (!params) {
        params = {};
    }
    let error_message = "";
    if(params.code){
        switch(params.code) {
            case 1: error_message = "Errore generico"; break;
            case 2: error_message = "Id non presente"; break;
            case 3: error_message = "Impossibile caricare il contenuto"; break;
            case 4: error_message = "Tutti i campi devono essere presenti"; break;
            case 5: error_message = "Impossibile inserire il contenuto"; break;
            case 6: error_message = "Errore durante l'upload del file"; break;
            case 7: error_message = "Errore di login (OpenId)"; break;
            case 8: error_message = "Errore nel caricamento della risorsa Learning Designer"; break;
            case 9: error_message = "Errore nel caricamento dei contenuti, effettua di nuovo il login"; break;
            default:
                error_message = "Errore generico"
        }   
    }
    return {
        Uploading: {
            uploadingMessage: 'Upload in corso...',

        },
        Errors: {
            generic: `${error_message}`,
        },
        ImportFromId: {
            header: `Immetti l'ID di un contenuto Idea`,
            ok: `Importa`,
            cancel: `Annulla`
        },
        ImportFromLDJSON: {
            preview: `Anteprima`,
            header: `Incolla l'ID di un contenuto di Learning Designer`,
            ok: `Importa`,
            cancel: `Annulla`
        },
        ImportFromLDJSONError: {
            header: `Errore nel caricamento JSON`,
            button: `Chiudi`
        }
    }
}