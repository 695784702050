export const LOG_USER = "LOG_USER";
export const LOGOUT = "LOGOUT";
export const WILL_GET_USERINFO = "WILL_GET_USERINFO";
export const DID_GET_USERINFO = "DID_GET_USERINFO";

export const WILL_SET_COOKIE = "WILL_SET_COOKIE";
export const DID_SET_COOKIE = "DID_SET_COOKIE";
export const SET_USERINFO = "SET_USERINFO";
export const SET_PATHNAME = "SET_PATHNAME";

export const WILL_CHECK_COOKIE = "WILL_CHECK_COOKIE"
export const SET_AUTHENTICATED = "SET_AUTHENTICATED"

export interface LogUserAction {
    type: typeof LOG_USER,
}

export interface LogoutUserAction {
    type: typeof LOGOUT,
}
export interface WillGetUserInfo {
    type: typeof WILL_GET_USERINFO
}
export interface DidGetUserInfo {
    type: typeof DID_GET_USERINFO
    userinfo: any
}
export interface WillSetCookieAction {
    type: typeof WILL_SET_COOKIE,
    cookie: any
}
export interface DidSetCookieAction {
    type: typeof DID_SET_COOKIE,
    cookie: any
}
export interface SetUserInfoAction {
    type: typeof SET_USERINFO,
    userinfo: any
}

export interface SetPathnameAction {
    type: typeof SET_PATHNAME,
    pathname: string
}
export interface SetAuthenticated {
    type: typeof SET_AUTHENTICATED,
    auth: boolean
}

export interface WillCheckCookie {
    type: typeof WILL_CHECK_COOKIE
}
export type IdeaAuthTypes =
    LogUserAction | SetUserInfoAction | WillGetUserInfo | DidSetCookieAction |
    LogoutUserAction | DidGetUserInfo |
    WillSetCookieAction | WillCheckCookie | SetAuthenticated |
    SetPathnameAction; 
