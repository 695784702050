export const WILL_SEARCH_TERM = "WILL_SEARCH_TERM";
export const DID_SEARCH_TERM = "DID_SEARCH_TERM";
export const DID_SET_SEARCH_TERM = "DID_SET_SEARCH_TERM"

export interface WillSearchTerm {
    type: typeof WILL_SEARCH_TERM,
    searchTerm: string
}
export interface DidSearchTerm {
    type: typeof DID_SEARCH_TERM
    results: any,
    searchBool: boolean
}

export interface DidSetSearchTerm {
    type: typeof DID_SET_SEARCH_TERM
    searchTerm: string
}


export type IdeaSearchTypes =
    | WillSearchTerm
    | DidSearchTerm
    | DidSetSearchTerm;

