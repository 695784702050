import { FETCH_CONTENTS, LIST_CONTENTS, SET_CONTENTS, ADD_CONTENT } from "../constants/content";
import { IdeaContentTypes } from "../constants/content";

import * as IdeaTypeEnums from "../constants/content";

export const listContents = (data: any): IdeaContentTypes => {
  return { type: LIST_CONTENTS, data: data }
};

export const fetchContents = (query: string): IdeaContentTypes => {
  return { type: FETCH_CONTENTS, query: query }
};
export const getContent = (query: string, content: string): IdeaContentTypes => {
  return { type: IdeaTypeEnums.GET_CONTENT, query: query, content: content }
};
export const getPublicContent = (query: string, content: string): IdeaContentTypes => {
  console.log("in get action", content)
  return { type: IdeaTypeEnums.GET_PUBLIC_CONTENT, query: query, content: content }
};
export const SetCurrentContentAction = (current: any): IdeaContentTypes => {
  return { type: IdeaTypeEnums.SET_CURRENT_CONTENT, current: current }
};
export const SetPublicCurrentContentAction = (publiccurrent: any): IdeaContentTypes => {
  return { type: IdeaTypeEnums.SET_PUBLIC_CURRENT_CONTENT, publiccurrent: publiccurrent }
};
export const setContents = (contents: any): IdeaContentTypes => {
  return { type: SET_CONTENTS, contents: contents }
};
export const addContent = (mutationParams: any): IdeaContentTypes => {
  return { type: ADD_CONTENT, mutationParams: mutationParams }
};
export const setFormData = (params: any): IdeaContentTypes => {
  return { type: IdeaTypeEnums.SET_FORMDATA, params: params }
};
export const unsetFormdata = (params: any): IdeaContentTypes => {
  return { type: IdeaTypeEnums.UNSET_FORMDATA }
};
export const addContentOk = (val: any): IdeaContentTypes => {
  return { type: IdeaTypeEnums.ADD_CONTENT_OK, ok: val }
};
export const addContentNotOk = (val: any): IdeaContentTypes => {
  return { type: IdeaTypeEnums.ADD_CONTENT_NOTOK, notok: val }
};


export const startUpload = (file: any, path: string): IdeaContentTypes => {
  return { type: IdeaTypeEnums.UPLOADING, status: true, file: file, path: path }
};

export const startAddContent = (file: any, path: string): IdeaContentTypes => {
  return { type: IdeaTypeEnums.START_ADDCONTENT, file: file, path: path }
}

export const abortUpload = (val: any): IdeaContentTypes => {
  return { type: IdeaTypeEnums.ABORT_UPLOAD }
};

export const uploadSuccess = (): IdeaContentTypes => {
  return { type: IdeaTypeEnums.UPLOAD_SUCCESS }
};

export const uploadUpdateProgress = (value: any): IdeaContentTypes => {
  return { type: IdeaTypeEnums.UPLOAD_UPDATE_PROGRESS, value: value }
};

export const setAppState = (state: IdeaTypeEnums.AppState, params?: any): IdeaContentTypes => {
  return { type: IdeaTypeEnums.SET_APP_STATE, appstate: state, params: params }
};

export const didSetFormErrors = (errors: IdeaTypeEnums.IdeaContentTypes): IdeaContentTypes => {
  return { type: IdeaTypeEnums.SET_FORM_ERRORS, errors: errors }
};

export const setAppError = (code: IdeaTypeEnums.AppError, returnstate?: IdeaTypeEnums.AppState, params?: any): IdeaContentTypes => {
  return { type: IdeaTypeEnums.SET_ERROR, code: code, returnstate: returnstate, params: params }
};

export const clearError = (): IdeaContentTypes => {
  return { type: IdeaTypeEnums.SET_ERROR, code: IdeaTypeEnums.AppError.NO_ERROR, params: {} }
};


export const setAppStateClipboard = (clipb: IdeaTypeEnums.AppState): IdeaContentTypes => {
  return { type: IdeaTypeEnums.SET_APP_STATE_CLIPBOARD, clipboardData: clipb }
};

export const importFromLDJSON = (url: string, params: any): IdeaContentTypes => {
  return { type: IdeaTypeEnums.IMPORT_LD_JSON, url: url, params: params }
};


export const formSubmitNewContent = (id: string, data: any): IdeaContentTypes => {
  return { type: IdeaTypeEnums.FORM_SUBMIT_NEW_CONTENT, id: id, data: data }
};

export const formSubmitForkContent = (id: string, data: any): IdeaContentTypes => {
  return { type: IdeaTypeEnums.FORM_SUBMIT_FORK_CONTENT, id: id, data: data }
};


export const setLDJSONfromURL = (url: string): IdeaContentTypes => {
  return { type: IdeaTypeEnums.SET_LDJSON_FROM_URL, url: url }
};
export const didSetLDJSONfromURL = (url: string, json: string): IdeaContentTypes => {
  return { type: IdeaTypeEnums.DID_SET_LDJSON_FROM_URL, url: url, json: json }
};

export const setLDJSONfromID = (id: string): IdeaContentTypes => {
  return { type: IdeaTypeEnums.SET_LDJSON_FROM_ID, id: id }
};
export const didSetLDJSONfromID = (id: string, json: string): IdeaContentTypes => {
  return { type: IdeaTypeEnums.DID_SET_LDJSON_FROM_ID, id: id, json: json }
};


