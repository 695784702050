export const convertActionColor = (prefix) => {
    switch(prefix){
        case "pro":
            return "#BDEA75";
        case "pra":
            return "#BB98DC";
        case "inv":
            return "#F8807F";
        case "dis":
            return "#7AAEEA";
        case "col":
            return "#FFCD00";
        case "rwl":
        default:
            return "#67DBF2";
    }
}

export const convertActionType = (prefix) => {
    switch(prefix){
        case "pro":
            return {value: "pro", label: "Produci"}
        case "pra":
            return {value: "pra", label: "Pratica"}
        case "inv":
            return {value: "inv", label: "Investiga"}
        case "dis":
            return {value: "dis", label: "Discuti"}
        case "col":
            return {value: "col", label: "Collabora"}
        case "rwl":
        default:
            return {value: "rwl", label: "Leggi/Guarda/Ascolta"}
    }
}