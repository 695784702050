import React from 'react';

import {
    Row, Col,Card, CardHeader, CardBody} from 'reactstrap';
import BarChartComponent from './PublicBarChartComponent';
import { convertActionColor, convertActionType} from './TLAActionTypes';

const _ = require('lodash');


export const LDReport = (props) => {
    console.log('ANTEPRIMA', props.data, typeof props.data);
    return (
        !_.isEmpty(props.data) && <React.Fragment>
            <Card style={{ marginTop: 12 }}>
                <CardHeader style={{ height: 50 }}>
                    <div style={{ float: "left" }}>Learning Designer</div>
                </CardHeader>
                <CardBody style={{textAlign: "left"}}>
                    <Row>
                        <Col md={12}>
                            <Row style={{ marginBottom: 10 }}>
                                <Col md={1}>Titolo:</Col>
                                {_.isEmpty(props.data.name)? <Col>Titolo non presente</Col>:<Col>{props.data.name}</Col>}
                            </Row><hr />
                            <Row style={{ marginBottom: 10 }}>
                                <Col md={1}>Topic:</Col>
                                <Col>{props.data.topic}</Col>
                            </Row><hr />
                            <Row style={{ marginBottom: 10 }}>
                                <Col md={1}>Descrizione:</Col>
                                <Col>
                                    <Card style={{ margin: 0, padding: 0, width: "100%" }}>
                                        <CardBody style={{ margin: 0, padding: 2 }}>
                                            <div dangerouslySetInnerHTML={{ __html: props.data.description  }}></div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row><hr />
                            <Row style={{ marginBottom: 10 }}>
                                <Col md={1}></Col>
                                <Col>
                                    <Row>
                                        <Col md={3}>Dimensione della classe: <strong>{props.data.sizeOfClass}</strong></Col>
                                        <Col md={3}>Tempo stimato: <strong>{props.data.learningTime} minuti</strong></Col>
                                        <Col md={3}>Tempo effettivo: <strong>{props.data.designedLearningTime} minuti</strong></Col>
                                    </Row>
                                </Col>
                            </Row><hr />
                            <Row style={{ marginBottom: 10 }}>
                                <Col md={1}>Obiettivi:</Col>
                                <Col>{props.data.aims}</Col>
                            </Row><hr />
                            <Row style={{ marginBottom: 10 }}>
                                <Col md={1}>Risultati: </Col>
                                <Col>
                                    {props.data.outcomes && props.data.outcomes.map((outcome, index) => (
                                        <Row key={`${index}`}>
                                            <Col md={3} style={{ float: "left" }}>Conoscenza: <strong>{outcome.knowledge.replace(/[\[\]']+/g, "")}</strong></Col>
                                            <Col>Descrizione: <strong>{outcome.description}</strong></Col>
                                        </Row>
                                    ))}
                                </Col>
                            </Row><hr />
                            <Row style={{ marginBottom: 10 }}>
                                <Col md={1}>Bar: </Col>
                                <Col>
                                    <BarChartComponent data={props.data} store={{ subscribe: () => { } }} />
                                </Col>
                            </Row><hr />
                            <Row style={{ marginBottom: 10 }}>
                                <Col md={1}>TLA: </Col>
                                <Col>
                                    {props.data.tlas && props.data.tlas.map((tla, index) => (
                                        <Row style={{ marginBottom: 10 }} key={`${index}`}>
                                            <Card style={{ width: '100%' }}>
                                                <CardHeader>Titolo: <strong>{tla.title}</strong></CardHeader>
                                                <CardBody style={{}}>
                                                    <Row>
                                                        <Col md={3}>Note:</Col>
                                                        <Col>{tla.notes}</Col>
                                                    </Row>
                                                </CardBody>
                                                <CardHeader>Azioni:</CardHeader>
                                                <CardBody>
                                                    {tla.actions && tla.actions.map((action, actionIindex) => (
                                                        <Row style={{ marginBottom: 10 }} key={`${actionIindex}`}>
                                                            <Card style={{ width: '100%' }}>
                                                                <CardHeader>[{`${convertActionType(action.actionType).label}`}]: Descrizione: <strong>{action.description}</strong></CardHeader>
                                                                <CardBody style={{ margin: 0, padding: 0 }}>
                                                                    <Row style={{ margin: 0, padding: 0, minHeight: 50 }}>
                                                                        <Col md="auto" style={{ margin: 0, padding: 0, width: 20, backgroundColor: `${convertActionColor(action.actionType)}` }}></Col>
                                                                        <Col>
                                                                            <Row>
                                                                                <Col md={3}>Durata: <strong>{action.minutes}</strong></Col>
                                                                                <Col md={3}>Dimensione gruppo di lavoro: <strong>{action.students}</strong></Col>
                                                                                <Col md={3}>Presenza del docente: <strong>{action.teacherPresence ? "SI" : "NO"}</strong></Col>
                                                                            </Row>
                                                                        </Col>
                                                                    </Row>
                                                                </CardBody>
                                                            </Card>
                                                        </Row>
                                                    ))}
                                                </CardBody>
                                            </Card>
                                        </Row>
                                    ))}
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </CardBody>
            </Card>

        </React.Fragment>
    )
}