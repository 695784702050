import { createStore, applyMiddleware } from "redux";
import createSagaMiddleware from 'redux-saga';
import { takeLatest, all } from 'redux-saga/effects';
import thunk from "redux-thunk";
import { createRootReducer } from "../reducers/index";
import * as IdeaTypes from '../constants/content';
import { composeWithDevTools } from 'redux-devtools-extension';
import rootSaga from '../sagas';

import { createHashHistory as createHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';

export const history = createHistory({
    basename: window.location.pathname
});

const rootReducer = createRootReducer(history);
const composeEnhancers = composeWithDevTools({
    // Specify name here, actionsBlacklist, actionsCreators and other options if needed
});
const sagaMiddleware = createSagaMiddleware();

export const store = createStore(rootReducer,
    composeEnhancers(applyMiddleware(thunk),
        applyMiddleware(sagaMiddleware),
        applyMiddleware(routerMiddleware(history))
        ));
sagaMiddleware.run(rootSaga);
