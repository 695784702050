import * as IdeaSearchTypes from '../constants/search';
import { ActionCreator } from "redux";


export const willSearchTerm: ActionCreator<IdeaSearchTypes.WillSearchTerm> = (searchTerm: string) => {
    return { type: IdeaSearchTypes.WILL_SEARCH_TERM, searchTerm: searchTerm }
}

export const didSearchTerm: ActionCreator<IdeaSearchTypes.DidSearchTerm> = (results: any) => {
    return { type: IdeaSearchTypes.DID_SEARCH_TERM, results: results, searchBool: false }
}

export const didSetSearchTerm: ActionCreator<IdeaSearchTypes.DidSetSearchTerm> = (searchTerm: string) => {
    return { type: IdeaSearchTypes.DID_SET_SEARCH_TERM, searchTerm: searchTerm }
}

