import { put, call, take } from 'redux-saga/effects';
import { buffers, eventChannel, END } from 'redux-saga';

import { select, takeLatest } from 'redux-saga/effects';
import AWSAppSyncClient from 'aws-appsync';
import gql from 'graphql-tag';
import { setContents, addContentOk, addContentNotOk } from '../actions/content';
import * as IdeaActions from '../actions/content';
import * as IdeaAuthActions from '../actions/auth';
import * as IdeaTypes from '../constants/content';
import axios from 'axios';
import Amplify, { Auth, Storage } from 'aws-amplify';
import addMutation from '../../gql_operations/addContent';
import * as ContentApi from '../../api/content'

import { getCookie, getUserinfo } from '../selectors/auth';
import { getAppstate, getListContentQuery, getGetcontent, getGetPubliccontent, getPreviousAppstate, getCurrentAppstate, getCurrentData, getMutation, getFormData, getFormErrors } from '../selectors/content';

import { push, goBack } from 'connected-react-router';
import Cookies from 'js-cookie';

const jwtdecode = require('jwt-decode');
let config: any = {}
if (process.env.REACT_APP_STAGE === 'PROD') {
    config = require('../../config-prod.json');
} else {
    config = require('../../config-dev.json');
}


const uuidv4 = require('uuid/v4');
const _ = require('lodash');

export function* willLoadContentSagas() {
    yield takeLatest(IdeaTypes.FETCH_CONTENTS, fetchContents);
    yield takeLatest(IdeaTypes.UPLOADING, startUpload);
    yield takeLatest(IdeaTypes.START_ADDCONTENT, startAddNewContentProcessSaga);
    yield takeLatest(IdeaTypes.UPLOAD_SUCCESS, addcontentMutationSaga);
    yield takeLatest(IdeaTypes.FORM_SUBMIT_NEW_CONTENT, formSubmitNewContent);
    yield takeLatest(IdeaTypes.FORM_SUBMIT_FORK_CONTENT, formSubmitForkContent);
    yield takeLatest(IdeaTypes.ADD_CONTENT, addContent);
    yield takeLatest(IdeaTypes.IMPORT_LD_JSON, importLDJSON);
    yield takeLatest(IdeaTypes.GET_CONTENT, getContent);
    yield takeLatest(IdeaTypes.GET_PUBLIC_CONTENT, getPublicContent);
    yield takeLatest(IdeaTypes.SET_LDJSON_FROM_ID, setLDJSONFromID);
    yield takeLatest(IdeaTypes.SET_LDJSON_FROM_URL, setLDJSONFromID);
}

export function* fetchContents() {
    //get cookie
    //...https://datalanguage.com/features/s3-managed-uploads
    yield put(IdeaActions.setAppState(IdeaTypes.AppState.LOADING, {}));
    let prev = yield select(getPreviousAppstate);

    let cookie_str = yield select(getCookie);
    let appstate = yield select(getAppstate);
    let cq = yield select(getListContentQuery);
    //let jwt = jwtdecode(cookie.id_token);
    try {
        let cookie: any = {};
        if (cookie_str !== "") {
            cookie = JSON.parse(cookie_str);
            yield put(IdeaAuthActions.willGetUserInfo())
        }
        else {
            cookie = JSON.parse(Cookies.get(config.authentication.cookie))
            yield put(IdeaAuthActions.willSetCookie(Cookies.get(config.authentication.cookie)));

            yield put(IdeaAuthActions.willGetUserInfo())

        }
        const client = new AWSAppSyncClient({
            url: config.authentication.appsync.aws_appsync_graphqlEndpoint,
            region: config.authentication.appsync.aws_appsync_region,
            disableOffline: true,
            auth: {
                type: config.authentication.appsync.aws_appsync_authenticationType,
                jwtToken: async () => cookie.id_token, // Required when you use Cognito UserPools OR OpenID Connect. token object is obtained previously
            }
        });

        const results: any = yield call(async () => {
            let response = await client.query({
                query: gql(cq)
            });
            return response.data;
        });

        yield put(setContents(results.getAllUserContents as any[]));
        yield put(IdeaActions.setAppState(IdeaTypes.AppState.LIST_ITEMS, {}));
        yield put(IdeaActions.setAppError(IdeaTypes.AppError.NO_ERROR));
    }
    catch (e) {
        // yield put(push('/')) USER_CONTENTS_LOAD_FAILED
        console.log('fetch error', e);
        yield put(IdeaActions.setAppError(IdeaTypes.AppError.USER_CONTENTS_LOAD_FAILED, prev, {}));

        // yield put(push('/'));
    }
}

export function* addContent() {
    let prev = yield select(getPreviousAppstate);
    const userinfo = yield select(getUserinfo)
    let cookie_str = yield select(getCookie);
    let mut = yield select(getMutation);
    let cookie: any = {}
    try {
        cookie = JSON.parse(cookie_str);
    } catch (e) {
        console.log(e);
        cookie = Cookies.getJSON(config.authentication.cookie);

    }
    const client = new AWSAppSyncClient({
        url: config.authentication.appsync.aws_appsync_graphqlEndpoint,
        region: config.authentication.appsync.aws_appsync_region,
        disableOffline: true,
        auth: {
            type: config.authentication.appsync.aws_appsync_authenticationType,
            jwtToken: async () => cookie.id_token, // Required when you use Cognito UserPools OR OpenID Connect. token object is obtained previously
        }
    });
    console.log('Mutation params', mut)
    const results: any = yield call(async () => {
        try {
            let response = await client.mutate({
                mutation: gql(addMutation),
                variables: {
                    content: mut.content,
                    title: mut.title,
                    summary: mut.summary,
                    subject: mut.subject,
                    resources: mut.resources,
                    grade: mut.grade,
                    ideaThematic: mut.ideaThematic,
                    s3URL: mut.s3URL,
                    parent: mut.parent,
                    subtree: mut.subtree,
                    rootcontent: mut.rootcontent,
                    LDURL: mut.LDURL,
                    LDJSON: mut.LDJSON,
                    insertiontype: mut.insertiontype
                }
            }
            );
            console.log('GOOD MUTA', response)
            return response.data;
        }
        catch (e) {
            console.log('BAD MUTA', e)
            return { bad_mutation: e }
        }
    });
    if (results.bad_mutation) {
        yield put(addContentNotOk(true));
        yield put(IdeaActions.setAppError(IdeaTypes.AppError.BAD_MUTATION_ERROR, prev, {}));
    }
    else {
        yield put(addContentOk(true));
        //yield put(push('/oauth/listcontents'));
        // yield put(goBack());
    }

}

function uploadChannel(file, path) {
    return eventChannel(emitter => {
        Storage.configure({
            AWSS3: {
                bucket: config.s3configuration.s3Bucket,//Your bucket ARN;
                region: config.s3configuration.s3Region//Specify the region your bucket was created in;
            }
        });
        Storage.put(path, file, {
            progressCallback: function (progress) {
                console.log(progress.loaded, progress.total);
                emitter({ progress: progress.total > 0 ? progress.loaded / progress.total : -1 })
            }
        }).then((result) => {
            emitter({ result });
            emitter(END)
        }).catch(e => {
            emitter({ err: new Error('Upload failed') });
            emitter(END);
        })

        return () => {
            //what to do here
        }
    }, buffers.sliding(2))
}
export function* startUpload(action) {
    // federate login 
    let cookie_str = yield select(getCookie);

    let cookie = JSON.parse(cookie_str);
    let jwt = jwtdecode(cookie.id_token);
    let prev = yield select(getPreviousAppstate);

    console.log('jwt:', jwt, action.file, action.path);
    //log in
    let signinResult = yield call(async () => {
        try {
            Amplify.configure({
                "Auth": {
                    "identityPoolId": config.authentication.appsync.identityPoolId,
                    "region": config.authentication.appsync.aws_appsync_region,
                    "userPoolId": config.authentication.appsync.userPoolId,
                    "userPoolWebClientId": config.authentication.appsync.userPoolWebClientId
                },
                "API": {
                    "aws_appsync_graphqlEndpoint": config.authentication.appsync.aws_appsync_graphqlEndpoint,
                    "aws_appsync_authenticationType": config.authentication.appsync.aws_appsync_authenticationType,
                    "aws_appsync_region": config.authentication.appsync.aws_appsync_region,
                    "identityPoolId": config.authentication.appsync.identityPoolId
                }
            });
            let cred = await Auth.federatedSignIn(
                jwt.iss, {
                token: jwt.id_token,
                // identity_id, // Optional
                expires_at: jwt.exp * 1000 + new Date().getTime() // the expiration timestamp
            },
                jwt.username
            );
            return { ok: cred };

        }
        catch (e) {
            console.log('saga error', e);
            return { error: e };
        }
    });

    if (signinResult.error) {
        yield put(IdeaActions.setAppError(IdeaTypes.AppError.FEDERATED_SIGNIN_ERROR, prev, {}));
    }

    //log in end 
    // add content to dynamo via graphql
    const channel = yield call(uploadChannel, action.file, action.path);
    while (true) {
        const { progress, err, result } = yield take(channel);
        if (err) {
            console.log('UPLOAD_ERROR')
            yield put(IdeaActions.setAppError(IdeaTypes.AppError.UPLOAD_ERROR, prev, {}));

            return;
        }
        if (result) {
            yield put(IdeaActions.uploadSuccess())
            return;
        }
        yield put(IdeaActions.uploadUpdateProgress(progress * 100));
    }


}

// upload process, each of the following sagas executes the add content steps: upload to S3, 
// call grapqhl mutation, send success/abort/or error
export function* startAddNewContentProcessSaga(action) {
    yield put(IdeaActions.startUpload(action.file, action.path));
}

export function* addcontentMutationSaga() {
    let formData = yield select(getFormData);
    yield put(IdeaActions.addContent(formData))
}


export function* getContent() {
    let cookie_str = yield select(getCookie);
    let gc = yield select(getGetcontent);
    let prev = yield select(getPreviousAppstate);
    console.log("in get content: ", gc)

    let cookie = JSON.parse(cookie_str);
    if (_.isEmpty(gc.content)) {
        yield put(IdeaActions.setAppError(IdeaTypes.AppError.EMPTY_ID_ERROR, prev, {}));
    }
    else {
        try {
            const client = new AWSAppSyncClient({
                url: config.authentication.appsync.aws_appsync_graphqlEndpoint,
                region: config.authentication.appsync.aws_appsync_region,
                disableOffline: true,
                auth: {
                    type: config.authentication.appsync.aws_appsync_authenticationType,
                    jwtToken: async () => cookie.id_token, // Required when you use Cognito UserPools OR OpenID Connect. token object is obtained previously
                }
            });
            console.log("in get content: ", gc)
            const results: any = yield call(async () => {
                let response = await client.query({
                    query: gql(gc.query), variables: { content: gc.content }
                });
                console.log('Content.Data', response.data)

                return response.data;
            });
            results.getContent.LDJSON = results.getContent.LDJSON;
            yield put(IdeaActions.SetCurrentContentAction(results.getContent as any[]));
        }
        catch (e) {
            console.log('Get Content error', e)
            yield put(IdeaActions.setAppError(IdeaTypes.AppError.GET_CONTENT_QUERY_ERROR, prev, {}));
        }

    }
}
//*********************** */

export function* getPublicContent() {
    // let cookie_str = yield select(getCookie);
    let gc = yield select(getGetPubliccontent);
    let prev = yield select(getPreviousAppstate);

    // let cookie = JSON.parse(cookie_str);
    if (_.isEmpty(gc.publiccontent)) {
        console.log("in get content: ", gc)

        yield put(IdeaActions.setAppError(IdeaTypes.AppError.EMPTY_ID_ERROR, prev, {}));
    }
    else {
        try {
            const client = new AWSAppSyncClient({
                url: config.authentication.appsyncAPI_KEY.aws_appsync_graphqlEndpoint,
                region: config.authentication.appsyncAPI_KEY.aws_appsync_region,
                disableOffline: true,
                auth: {
                    type: "API_KEY",
                    apiKey: config.authentication.appsyncAPI_KEY.aws_appsync_apiKey,
                    // jwtToken: async () => cookie.id_token, // Required when you use Cognito UserPools OR OpenID Connect. token object is obtained previously
                }
            });
            console.log("in get content: ", gc)
            const results: any = yield call(async () => {
                let response = await client.query({
                    query: gql(gc.publicquery), variables: { content: gc.publiccontent }
                });
                console.log("response: ", response)
                return response.data;
            });
            yield put(IdeaActions.SetPublicCurrentContentAction(results.getContent as any[]));
        }
        catch (e) {
            console.log("inerror3", e)
            yield put(IdeaActions.setAppError(IdeaTypes.AppError.GET_CONTENT_QUERY_ERROR, prev, {}));
        }

    }
}

// export function* editImportedLDFromJSON() {
//     yield put(IdeaActions.setAppState(IdeaTypes.AppState.IMPORT_CONTENT_BY_JSON_PROCESSING, uuidv4()))
// }



export function* setLDJSONFromID(action) {
    let currState = yield select(getAppstate);
    console.log('action setLDJSONFromID', action)
    try {
        let ldjson = yield call(ContentApi.doLoadLDJSONfromId, action.id);
        console.log('ldjson setLDJSONFromID', ldjson)
        if (ldjson.data.name) {
            yield put(IdeaActions.didSetLDJSONfromID(action.id, ldjson.data))
        }
        else {
            yield put(IdeaActions.setAppError(IdeaTypes.AppError.IMPORT_CONTENT_BY_JSON_FAILED, currState, {}));
        }
    }
    catch {
        yield put(IdeaActions.setAppError(IdeaTypes.AppError.IMPORT_CONTENT_BY_JSON_FAILED, currState, {}));
    }
}

export function* importLDJSON(input: { type: IdeaTypes.IdeaContentTypes, url: string, params: any }) {
    console.log('Importing JSON', input);
    let prev = yield select(getPreviousAppstate);

    const results: any = yield call(async () => {
        try {
            let ld = await axios.get(`${config.learningDesignerConfiguration.jsonUrlPrefix}${input.url}`);
            if (ld.data.name)
                return { ok: ld.data };
            else {
                console.log('Error importing learning designer json');
                return { error: ld };
            }
        }
        catch (e) {
            console.log('Error importing learning designer json', e);
            return { error: e }
        }
    });
    const strip_html_tags = (str: string) => {
        if ((str === null) || (str === ''))
            return false;
        else
            str = str.toString();
        // return str.replace(/<[^>]*>/g, '').replace(/"/g, '\\"').replace(/[^\x00-\x7F]/g, "").replace(/\n/g, '')
        return str.replace(/<[^>]*>/g, '').replace(/\n/g, '')
    }
    console.log('NAME SUMMARY', strip_html_tags(results.ok.name), strip_html_tags(results.ok.description))
    if (results.ok) {
        let data = {
            title: strip_html_tags(results.ok.name),
            summary: strip_html_tags(results.ok.description),
            LDJSON: results.ok,
            LDURL: `${config.learningDesignerConfiguration.jsonUrlPrefix}${input.url}`
        }
        console.log('DATA', data)
        yield put(IdeaActions.SetCurrentContentAction(data as any));
    }
    else {
        yield put(IdeaActions.setAppError(IdeaTypes.AppError.IMPORT_CONTENT_BY_JSON_FAILED, prev, {}));

    }
}


export function* processErrors(action) {

}

export function* formSubmitNewContent(action: { type: IdeaTypes.IdeaContentTypes, id: string, data: any }) {
    let result = action.data;
    let prev = yield select(getPreviousAppstate);
    let current = yield select(getCurrentAppstate);
    let currentData = yield select(getCurrentData);
    let formErrors = yield select(getFormErrors);
    console.log("informerrors: ", formErrors)
    console.log('inside saga form submit', current, currentData)
    const file = result.values.s3URL.file;
    const name = result.values.s3URL.name;
  
    const filepath = `contents/mbz/${uuidv4()}/${name}`;
    let LDinfos = {}
    if (currentData.LDJSON) {
        LDinfos = {
            "designedLearningTime": currentData.LDJSON.designedLearningTime,
            "learningTime": currentData.LDJSON.learningTime,
            "outcomes": currentData.LDJSON.outcomes
        }
    }

    try {
        // if(result.values.subject || result.values.ideaThematic || result.values.resources || result.values.grade)
        // {

        // }
        yield put(IdeaActions.setFormData(
            {
                content: action.id,
                title: result.values.title,
                summary: result.values.summary,
                subject: result.values.subject.map((idea) => { return { subject: idea.ideab3, title: idea.label } }),
                resources: result.values.resources.map((idea) => { return idea.ideab3 }),
                grade: result.values.grade.ideab3,
                ideaThematic: result.values.ideaThematic.map((idea) => { return idea.ideab3 }),
                s3URL: filepath,
                parent: "none",
                subtree: action.id,
                rootcontent: action.id,
                LDJSON: _.isEmpty(currentData.LDJSON) ? undefined : JSON.stringify(currentData.LDJSON),
                LDURL: currentData.LDURL,
                insertiontype: "New"
            }
        ));


        yield put(IdeaActions.startAddContent(file, filepath))
    }
    catch (e) {
        console.log(e)
        yield put(IdeaActions.setAppError(IdeaTypes.AppError.EMPTY_FORM_FIELD, current));
    }
}

export function* formSubmitForkContent(action: { type: IdeaTypes.IdeaContentTypes, id: string, data: any }) {
    let result = action.data;
    let prev = yield select(getPreviousAppstate);
    let current = yield select(getCurrentAppstate);
    let oldForm = yield select(getCurrentData);
    let newId = uuidv4();
    const file = result.values.s3URL.file;
    const name = result.values.s3URL.name;
    let filepath = "";
    let parent = action.id
    let subtree = "";
    let rootcontent = "";
    let insertiontype = "";

    if (current === IdeaTypes.AppState.EDIT_CONTENT) { //updating content:parent's rootcontent, parent's subtree, parent as usual, new id
        subtree = result.values.subtree;
        rootcontent = result.values.rootcontent;
        insertiontype = "Update";
    } else {  //forking content: parent's rootcontent, subtree = new id, parent as usual, new id
        subtree = newId;
        rootcontent = result.values.rootcontent;
        insertiontype = "Fork";
    }

    // if (result.values.s3URL.file && (oldForm.s3URL !== name)) {
    //     // file not empty and updated
    //     filepath = `contents/mbz/${newId}/${name}`;
    // }
    // else if (result.values.s3URL.file) { 
    //     // file not updated
    //     filepath = `${name}`;
    // }
    // else {

    // }
    var splits= name.split(`/`)
    var fileName = splits[splits.length -1]    
    console.log("FILE FORK 1", splits, fileName)
    // console.log('FILE FORK', file, name, oldForm.s3URL)
    if(file !== null){
    if (!_.isEmpty(file) && (oldForm.s3URL === fileName)) {
        filepath = `contents/mbz/${newId}/${fileName}`;
    }
    else if (!_.isEmpty(file)) {
        filepath = `${fileName}`;
    }
    else {
            filepath = `contents/mbz/${newId}/${fileName}`;
    }
    }else{
        filepath = name
    }

    try {
        yield put(IdeaActions.setFormData(
            {
                content: newId,
                title: result.values.title,
                summary: result.values.summary,
                subject: result.values.subject.map((idea) => { return { subject: idea.ideab3, title: idea.label } }),
                resources: result.values.resources.map((idea) => { return idea.ideab3 }),
                grade: result.values.grade.ideab3,
                ideaThematic: result.values.ideaThematic.map((idea) => { return idea.ideab3 }),
                s3URL: filepath,
                parent: parent,
                subtree: subtree || action.id,
                LDURL: result.values.LDURL,
                LDJSON: result.values.LDJSON,
                insertiontype: insertiontype,
                rootcontent: rootcontent
            }
        ));
        console.log('UPLOAD---', file, fileName, oldForm.s3URL);
        if (result.values.s3URL.file && (oldForm.s3URL !== fileName)) {
            // file not empty and updated
            yield put(IdeaActions.startAddContent(file, filepath))
        }
        else if (result.values.s3URL.file) { // file not updated
            yield put(IdeaActions.startAddContent(file, filepath))
        }
        else {
            yield put(IdeaActions.uploadSuccess())
        }
    }
    catch (e) {
        console.log('inside saga form fork submit', e)
        yield put(IdeaActions.setAppError(IdeaTypes.AppError.EMPTY_FORM_FIELD, current));

    }

}


