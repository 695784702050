import { Badge, Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, Progress, Row, Col } from 'reactstrap';

import { useDispatch, useSelector, } from 'react-redux';
import { AppState, AppError } from '../redux/constants/content'
import { getAppstate, getApperror, getCurrentData, getUploadingState, getUpdateProgress } from '../redux/selectors/content'
import * as IdeaActions from '../redux/actions/content';
import React, { useState, useEffect } from 'react';
import { modals_strings } from '../strings';
import { LDReport } from './ldReport'
import './modals.css';
import { push, goBack } from 'connected-react-router';


const _ = require('lodash');
const uuidv4 = require('uuid/v4');

/**
https://piattaforma.ideab3.it/#/public/ld/
// 40df78a2-add0-44e9-8759-5c344694d227
// e9ca362b-a2e3-4e18-b24a-13b04bf906c4
https://piattaforma.ideab3.it/#/public/ai/c03a84c5-8f36-42fb-b0df-2b40cde0a163
https://piattaforma.ideab3.it/#/public/ssp/a7df1027-2a63-4275-af81-ce378bc98a93
 */

export const IdeaModalError = (props) => {
    const dispatch = useDispatch();
    const error = useSelector(getApperror);
    const prev = useSelector(getAppstate);

    return (
        <Modal isOpen={error.code > AppError.NO_ERROR}>
            <ModalBody>
                {modals_strings({ code: error.code }).Errors.generic}
            </ModalBody>
            <ModalFooter>
                <Button color="danger" onClick={() => {
                    dispatch(IdeaActions.clearError())
                    dispatch(IdeaActions.setAppState(error.returnstate))
                }}>{modals_strings().ImportFromLDJSONError.button}</Button>{' '}

            </ModalFooter>
        </Modal>
    )
}

export const ImportFromId = (props) => {
    const dispatch = useDispatch();
    const [open, setOpen] = useState(props.open);
    const [contentID, setContentID] = useState("");

    useEffect(() => {
        setOpen(props.open);
    }, [props.open]);
    const toggle = () => {
        dispatch(IdeaActions.setAppState(AppState.IMPORT_CONTENT_BY_ID_PROCESSING, contentID));
        dispatch(push(`/oauth/addcontent/${contentID}`))
        props.toggle(false);
    };

    return (
        <Modal size="lg" isOpen={open}>
            <ModalHeader>Importa Risorsa da ID</ModalHeader>
            <ModalBody>
                <Input placeholder={modals_strings().ImportFromId.header} value={contentID} onChange={(e) => { setContentID(e.target.value) }}></Input>
            </ModalBody>
            <ModalFooter>

                <div style={{ float: "center" }}>
                    <Button color="success" onClick={toggle}>{modals_strings().ImportFromId.ok}</Button>{' '}
                    <Button color="danger" onClick={() => {
                        props.toggle(false);
                    }}>
                        {modals_strings().ImportFromId.cancel}</Button>
                </div>

            </ModalFooter>
        </Modal>

    )
}

export const ImportFromLDJSON = (props) => {
    const dispatch = useDispatch();
    const currentData = useSelector(getCurrentData)

    const [open, setOpen] = useState(props.open)
    const [json, setJSON] = useState("");

    useEffect(() => {
        setOpen(props.open);
        setJSON("");
    }, [props.open]);
    useEffect(() => {

    }, [currentData])
    const toggle = () => {
        const id = uuidv4();
        dispatch(IdeaActions.setAppState(AppState.IMPORT_CONTENT_BY_JSON_PROCESSING, id));
        dispatch(push(`/oauth/addcontent/${id}`))
        props.toggle(false);
    }
    return (
        <Modal size="md" style={{ maxWidth: '1600px', width: '80%' }} isOpen={open}>
            <ModalHeader>Importa Contenuto da Learning Designer</ModalHeader>
            <ModalBody>
                <Input placeholder={modals_strings().ImportFromLDJSON.header} value={json} onChange={(e) => setJSON(e.target.value)}></Input>
            </ModalBody>
            <ModalFooter>
                <Col>
                    <Row>
                        {!_.isEmpty(currentData) && <Button color="success" onClick={toggle}>{modals_strings().ImportFromLDJSON.ok}</Button>}{' '}
                        <Button color="info" onClick={() => {
                            dispatch(IdeaActions.importFromLDJSON(json));
                        }}>{modals_strings().ImportFromLDJSON.preview}</Button>{' '}

                        <Button color="danger" onClick={() => {
                            setJSON("");
                            dispatch(IdeaActions.SetCurrentContentAction({}));
                            props.toggle(false);
                        }}>
                            {modals_strings().ImportFromLDJSON.cancel}</Button>
                    </Row>
                    <Row>
                        {!_.isEmpty(currentData) && <LDReport data={currentData.LDJSON} />}
                    </Row>
                </Col>
            </ModalFooter>
        </Modal>
    )
}

export const LDJSONpreview = (props) => {
    const currentData = useSelector(getCurrentData);
    const [up, setOpen] = useState(props.open)
    console.log('Preview LDJSON', props, currentData.LDJSON)
    return (
        <Modal size="md" style={{ maxWidth: '1600px', width: '80%' }} isOpen={props.open}>
            <ModalBody>
                <Col>
                    <Row>
                        <Button color="info" onClick={() => { props.toggle(false) }}>Chiudi</Button>
                    </Row>
                    <Row>
                        {!_.isEmpty(currentData) && <LDReport data={props.json ? JSON.parse(props.json):  JSON.parse(currentData.LDJSON)} />}
                    </Row>
                </Col>
            </ModalBody>
        </Modal>
    )
}

export const UpdateLDJSONModal = (props) => {
    const dispatch = useDispatch();

    const [json, setJSON] = useState("");

    return (
        <Modal size="md" style={{ maxWidth: '1600px', width: '80%' }} isOpen={props.open}>

            <ModalHeader>Inserisci l'Id della risorsa</ModalHeader>
            <ModalBody>
                <Input placeholder={modals_strings().ImportFromLDJSON.header} value={json} onChange={(e) => setJSON(e.target.value)}></Input>
                <Col>
                    <Row>
                        <Button color="warning" onClick={() => { 
                            dispatch(IdeaActions.setLDJSONfromID(json));
                            setJSON("")
                            props.toggle(false)
                        }}>Importa</Button>
                        <Button color="info" onClick={() => { props.toggle(false) }}>Chiudi</Button>
                    </Row>
                </Col>
            </ModalBody>
        </Modal>
    )
}

export const ImportFromLDJSONError = (props) => {

    const dispatch = useDispatch();
    const appstate = useSelector(getAppstate);

    return (
        <Modal isOpen={appstate.current === AppState.IMPORT_CONTENT_BY_JSON_FAILED}>
            <ModalBody>
                {modals_strings().ImportFromLDJSONError.header}
            </ModalBody>
            <ModalFooter>
                <Button color="danger" onClick={() => {

                    dispatch(IdeaActions.setAppState(AppState.LIST_ITEMS));
                    dispatch(push('/oauth/listcontents'))
                }}>{modals_strings().ImportFromLDJSONError.button}</Button>{' '}

            </ModalFooter>
        </Modal>
    )
}

export const UploadingModal = (props) => {
    const uploading = useSelector(getUploadingState);
    const update_progress = useSelector(getUpdateProgress);

    return (
        <Modal isOpen={uploading} toggle={!uploading}  >
            <ModalHeader>{modals_strings().uploadingMessage}</ModalHeader>
            <ModalBody>
                <div>
                    <div className="text-center"><h2>{Math.trunc(update_progress)}%</h2></div>
                    <Progress bar color="info" value={Math.trunc(update_progress)} style={{ width: '70%' }} />
                </div>
            </ModalBody>
        </Modal>

    );
}

